import React, { PureComponent } from 'react';
import ReactTable from 'react-table';
import ModalComp from './ModalComp';
import { Loading } from '.';
import { formatDate, sortDate, callLambda } from '../helpers';
import './DataTable.css';
import 'react-table/react-table.css';


class CaseSupportTable extends PureComponent {
  state = {
    caseData: [],
    pickListValues: {},
    showModal: false,
    isFetching: true,
    whichModal: 'CaseDetailModal',
    selectedCaseData: {},
    searchText: '',
  }

  componentIsMounted = false;

  componentDidMount = async () => {
    this.componentIsMounted = true;
    await this.fetchExistingCases();
  }

  componentWillUnmount() {
    this.componentIsMounted = false;
  }

  openCaseDetailModal = ({ caseId, contactName }) => {
    this.setState({
      showModal: true,
      selectedCaseData: { caseId, contactName },
      whichModal: 'CaseDetailModal',
    });
  }

  openNewCaseModal = () => {
    this.setState({ showModal: true, whichModal: 'NewCaseModal' });
  }

  closeModal = () => {
    this.setState({ showModal: false, whichModal: '' });
  }

  fetchExistingCases = async () => {
    try {
      const result = await callLambda('get', 'cases');

      if (this.componentIsMounted) {
        this.setState({
          caseData: result.cases,
          pickListValues: result.pickListValues,
          isFetching: false,
        });
      }
    } catch (e) {
      if (e.message.includes('401')) {
        this.setState({ isFetching: false, showModal: true, whichModal: 'ExpiredSessionModal' });
      } else if(e.message.includes('504')) {
        // in the event of a timeout, bring up TimeoutModal
        this.setState({isFetching: false, showModal: true, whichModal: 'TimeoutModal' });
      } else {
        this.setState({
          isFetching: false,
          caseData: [],
        });
      }
    }
  }

  getTableHeaders = () => {
    const { pickListValues } = this.state;
    const { priorityPicklist, statusPicklist } = pickListValues;
    const headers = [
      {
        Header: 'Case Number', accessor: 'caseNumber', maxWidth: 150, style: { textAlign: 'center' },
      },
      { Header: 'Contact Name', accessor: 'contactName', style: { textAlign: 'center' } },
      { Header: 'Subject', accessor: 'subject' },
      {
        Header: 'Priority',
        accessor: 'priority',
        minWidth: 100,
        style: { textAlign: 'center' },
        sortMethod: (a, b) => {
          if (a === b) return 0;
          return priorityPicklist.indexOf(a) > priorityPicklist.indexOf(b) ? 1 : -1;
        },
      },
      {
        Header: 'Date Opened',
        accessor: 'dateOpened',
        style: { textAlign: 'center' },
        maxWidth: 200,
        sortMethod: sortDate,
      },
      {
        Header: 'Status',
        accessor: 'status',
        maxWidth: 150,
        sortMethod: (a, b) => {
          if (a === b) return 0;
          return statusPicklist.indexOf(a) > statusPicklist.indexOf(b) ? 1 : -1;
        },
      },
    ];
    return headers;
  }

  getTableData = () => {
    const { caseData } = this.state;

    const tableData = caseData.map((row) => {
      const rowData = {
        caseNumber: row.CaseNumber !== null ? row.CaseNumber : '',
        contactName: row.Contact !== null ? row.Contact.Name : '',
        subject: row.Subject !== null ? row.Subject : '',
        priority: row.Priority !== null ? row.Priority : '',
        dateOpened: row.CreatedDate !== null ? formatDate(row.CreatedDate) : '',
        status: row.Status !== null ? row.Status : '',
        caseId: row.Id !== null ? row.Id : '',
      };
      return rowData;
    });

    return tableData;
  }

  render() {
    const {
      whichModal, isFetching, showModal, searchText, selectedCaseData, pickListValues,
    } = this.state;

    const columns = this.getTableHeaders();
    let data = this.getTableData();

    if (searchText) {
      const normalizedSearchText = searchText.toUpperCase();
      data = data.filter(row => (
        row.contactName.toUpperCase().includes(normalizedSearchText)
        || row.caseNumber.toUpperCase().includes(normalizedSearchText)
        || row.subject.toUpperCase().includes(normalizedSearchText)
        || row.dateOpened.toUpperCase().includes(normalizedSearchText)
        || row.status.toUpperCase().includes(normalizedSearchText)
      ));
    }

    let content;

    if (isFetching || whichModal === 'ExpiredSessionModal') {
      content = (
        <Loading />
      );
    } else {
      content = (
        <div className="dataTableContainer">
          <div className="formLine">
            <input
              className="search-box"
              value={searchText}
              placeholder="Search"
              onChange={e => this.setState({ searchText: e.target.value })}
            />
            <button
              type="button"
              className="offBtn"
              style={{
                position: 'absolute', right: '9.5%', width: 200, height: 45,
              }}
              onClick={this.openNewCaseModal}
            >
              Submit New Case
            </button>
          </div>
          <ReactTable
            className="-striped case-support"
            data={data}
            columns={columns}
            showPagination={false}
            sortable
            defaultSorted={[{ id: 'status' }]}
            resizable={false}
            minRows={2}
            pageSize={data.length}
            getTrProps={(state, rowInfo) => (
              { onClick: () => this.openCaseDetailModal(rowInfo.original) })
            }
          />
        </div>
      );
    }

    return (
      <div className="dataTableContainer">
        <ModalComp
          showModal={showModal}
          closeModal={this.closeModal}
          whichModal={whichModal}
          selectedCaseData={selectedCaseData}
          pickListValues={pickListValues}
          fetchExistingCases={this.fetchExistingCases}
        >
          <div className="tpBtnsRow">
            <div className="tpModalBtn">
              <button className="tpBtn" onClick={this.closeModal} type="button">
                Cancel
              </button>
            </div>
          </div>
        </ModalComp>
        <div>
          {content}
        </div>
      </div>
    );
  }
}

export default CaseSupportTable;
