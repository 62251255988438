import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { callLambda } from '../../helpers';
import { Loading } from '..';
import {
  LicenseModal, InfoModal, SuccessModal, ExpiredSessionModal,
} from '.';
import {
  INITIAL, TRANSFER_LIMIT, SUCCESS, EXPIRED, SERVER_ERROR,
} from './modalStates';


class TransferLicenseModal extends PureComponent {
  static propTypes = {
    product: PropTypes.string,
    serialNumber: PropTypes.string,
    startingHostID: PropTypes.string,
    userName: PropTypes.string,
    expireDate: PropTypes.string,
    licensedServerId: PropTypes.string,
    closeModal: PropTypes.func.isRequired,
    fetchLicenseData: PropTypes.func.isRequired,
    showModal: PropTypes.bool.isRequired,
    isFloating: PropTypes.bool.isRequired,
    renewing: PropTypes.bool.isRequired,
  };

  state = {
    key: '',
    hostID: '',
    modalState: INITIAL,
    isFetching: true,
    buttonText: 'Copy',
    error: false,
  };

  componentDidMount = async () => {
    const { startingHostID } = this.props;
    await this.checkTransferLimit();
    this.setState({ hostID: startingHostID });
  };

  componentDidUpdate = async (prevProps) => {
    const { showModal } = this.props;
    if (showModal && !prevProps.showModal) {
      const { startingHostID } = this.props;
      await this.checkTransferLimit();
      this.setState({ hostID: startingHostID });
    }
  };

  handleCopy = () => {
    this.setState({ buttonText: 'Copied' });
  };

  handleChange = (event) => {
    if (event.target.name === 'modalHostID') {
      this.setState({ hostID: event.target.value });
    }
  };

  handleClose = () => {
    const { closeModal } = this.props;
    this.setState({
      modalState: INITIAL,
      hostID: '',
      key: '',
      buttonText: 'Copy',
    });
    closeModal();
  };

  checkTransferLimit = async () => {
    const { licensedServerId } = this.props;

    this.setState({ isFetching: true, error: false });

    const data = {
      licensedServer: licensedServerId,
    };

    try {
      const result = await callLambda('post', 'canTransfer', data);

      if (result) {
        this.setModalState(INITIAL);
      } else {
        this.setModalState(TRANSFER_LIMIT);
      }
    } catch (e) {
      if (e.message.includes('401')) {
        this.setModalState(EXPIRED);
      } else {
        this.setModalState(SERVER_ERROR);
      }
    }
  };

  setModalState = (modal) => {
    this.setState({ isFetching: false, modalState: modal });
  };

  transferLicense = async () => {
    const { hostID } = this.state;
    const { licensedServerId, fetchLicenseData } = this.props;

    this.setState({ isFetching: true, error: false });

    const data = {
      hostId: hostID,
      licensedServer: licensedServerId,
    };

    try {
      const result = await callLambda('post', 'transfer', data);

      // display "Invalid Host ID" or "Internal Server Error"
      if (result.error) {
        this.setState({
          key: result.error,
          error: true,
          isFetching: false,
        });
      } else {
        fetchLicenseData();
        this.setState({
          key: result,
          modalState: SUCCESS,
          isFetching: false,
        });
      }
    } catch (e) {
      if (e.message.includes('401')) {
        this.setModalState(EXPIRED);
      } else {
        this.setModalState(SERVER_ERROR);
      }
    }
  };

  render() {
    const {
      key,
      hostID,
      modalState,
      isFetching,
      buttonText,
      error,
    } = this.state;
    const {
      product,
      serialNumber,
      userName,
      expireDate,
      isFloating,
      renewing,
    } = this.props;
    let modalContent;

    if (modalState === INITIAL) {
      const action = renewing ? 'Renew' : 'Transfer';
      const hostIDText = isFloating
        ? ''
        : 'enter the Host ID of the system you wish to assign it to, then ';
      modalContent = (
        <LicenseModal
          header={renewing ? 'Renew License Key' : 'Transfer License Key'}
          message={`To ${action.toLowerCase()} your license key for this product, ${hostIDText} click "${action} License Key".`}
          product={product}
          serialNumber={serialNumber}
          userName={userName}
          isFloating={isFloating}
          hostID={hostID}
          expireDate={expireDate}
          error={error}
          licenseKey={key}
          onClose={this.handleClose}
          onChange={this.handleChange}
          onAction={this.transferLicense}
          actionText={`${action} License Key`}
        />
      );
    } else if (modalState === SUCCESS) {
      modalContent = (
        <SuccessModal
          header={renewing ? 'Key Renewed' : 'Key Transferred'}
          licenseKey={key}
          onClose={this.handleClose}
          handleCopy={this.handleCopy}
          buttonText={buttonText}
        />
      );
    } else if (modalState === TRANSFER_LIMIT) {
      const action = renewing ? 'renew' : 'transfer';
      modalContent = (
        <InfoModal
          header="Limit Reached"
          message={`This License reached its ${action} limit...  please contact Customer Support.`}
          onClose={this.handleClose}
        />
      );
    } else if (modalState === SERVER_ERROR) {
      modalContent = (
        <InfoModal
          header="Something Went Wrong"
          message="Please retry or contact support."
          onClose={this.handleClose}
          email="support@eggplantsoftware.com"
        />
      );
    } else if (modalState === EXPIRED) {
      modalContent = <ExpiredSessionModal />;
    }

    return (
      <div className="tpModalContent">
        {!isFetching ? (
          <div className="tpModalContent">{modalContent}</div>
        ) : (
          <Loading />
        )}
      </div>
    );
  }
}

export default TransferLicenseModal;
