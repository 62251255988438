import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { callLambda } from '../../helpers';
import { Loading } from '..';
import { InfoModal, ExpiredSessionModal } from '.';
import { SUCCESS, EXPIRED, SERVER_ERROR } from './modalStates';


class EmailAllModal extends PureComponent {
  static propTypes = {
    showModal: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    licensedServers: PropTypes.array.isRequired,
  };

  state = {
    isFetching: false,
    modalState: '',
  }

  componentDidMount = async () => {
    await this.sendLicensesEmail();
  }

  componentDidUpdate = async (prevProps) => {
    const { showModal } = this.props;
    if (showModal && !prevProps.showModal) {
      await this.sendLicensesEmail();
    }
  }

  sendLicensesEmail = async () => {
    const { licensedServers } = this.props;

    this.setState({ isFetching: true });

    try {
      await callLambda('post', 'emailLicense', { licensedServers });

      this.setModalState(SUCCESS);
    } catch (e) {
      if (e.message.includes('401')) {
        this.setModalState(EXPIRED);
      } else {
        this.setModalState(SERVER_ERROR);
      }
    }
  }

  setModalState = (modal) => {
    this.setState({ isFetching: false, modalState: modal });
  }

  render() {
    const { modalState, isFetching } = this.state;
    const { closeModal } = this.props;

    let modalContent;

    if (modalState === SUCCESS) {
      modalContent = (
        <InfoModal
          header="Your request is being processed"
          message="If you do not get an email please retry or contact support."
          onClose={closeModal}
        />
      );
    } else if (modalState === SERVER_ERROR) {
      modalContent = (
        <InfoModal
          header="Something went wrong"
          message="Please retry or contact support."
          onClose={closeModal}
          email="support@eggplantsoftware.com"
        />
      );
    } else if (modalState === EXPIRED) {
      modalContent = (
        <ExpiredSessionModal />
      );
    }

    return (
      <div className="tpModalContent">
        {!isFetching
          ? (
            <div className="tpModalContent">
              {modalContent}
            </div>
          ) : (
            <Loading />
          )}
      </div>
    );
  }
}

export default EmailAllModal;
