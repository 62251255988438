import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import './login.css';
import { ForgotPwdButton, Spinner } from '../components';
import { callLambda } from '../helpers';
import ModalComp from '../components/ModalComp';

class Login extends PureComponent {
  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    handleLoggedInUser: PropTypes.func.isRequired,
    handleLogout: PropTypes.func.isRequired,
  }

  state = {
    email: '',
    password: '',
    jwt: '',
    loading: false,
    loginError: false,
    showModal: false,
    whichModal: '',
  }

  componentDidMount() {
    const { handleLogout } = this.props;
    handleLogout();
    this.handleSalesforceSupportLogin();
  }

  handleSalesforceSupportLogin = () => {
    const { location } = this.props;
    const parsed = queryString.parse(location.search);
    if (parsed.jwt) {
      this.setState({
        jwt: parsed.jwt,
      }, () => {
        this.logInJWT();
      });
    }
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
      loginError: false,
    });
  }

  logInJWT = async () => {
    const { jwt } = this.state;
    const data = { jwt };
    const { handleLoggedInUser, history } = this.props;

    this.setState({ loading: true });

    try {
      const result = await callLambda('post', 'loginJWT', data);
      document.cookie = `jwt=${result[0]}`;
      document.cookie = `userName=${result[1]}`;
      handleLoggedInUser();
      this.setState({ loading: false });
      history.push('/casesupport');
      //history.push('/licenses');
    } catch (e) {
      if (e.message.includes('504')) {
        this.setState({loading: false, showModal: true, whichModal: 'TimeoutModal' });
      } else {
        this.setState({ loading: false, loginError: true });
      }
    }
  }

  logIn = async () => {
    const { email, password } = this.state;
    const { handleLoggedInUser, history } = this.props;

    const data = {
      username: email,
      password: password,
    };

    this.setState({ loading: true });

    try {
      const result = await callLambda('post', 'login', data);
      document.cookie = `jwt=${result[0]}`;
      document.cookie = `userName=${result[1]}`;
      handleLoggedInUser();
      this.setState({ loading: false });
      history.push('/casesupport');
      // history.push('/licenses');
    } catch (e) {
      if (e.message.includes('504')) {
        this.setState({loading: false, showModal: true, whichModal: 'TimeoutModal' });
      } else {
        this.setState({ loading: false, loginError: true });
      }
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.logIn();
  };

  closeModal = () => {
    this.setState({ showModal: false });
  };

  render() {
    const p1 = 'Welcome to the Eggplant customer portal. You can manage your support cases from this website. You now manage your Eggplant licenses from the Keysight Software Manager website at';
    const p2 = 'To request access to Greenhouse or if you have any questions please email your account manager or';
    const { loginError, loading, showModal, whichModal } = this.state;
    return (
      <div className="ep-login-grid">
        <ModalComp
          showModal={showModal}
          whichModal={whichModal}
          closeModal={this.closeModal}
        />
        <div className="ep-login-vbge">
          <h2>Sign in</h2>
          <p>{p1}
          &nbsp;<a className="redlink" href="https://www.keysight.com/find/softwaremanager" target="_blank" rel="noopener noreferrer">https://www.keysight.com/find/softwaremanager</a>
          </p>
          <p>
            {p2}
            &nbsp;<a id="ep-link" href="mailto:support@eggplant.io">support@eggplant.io</a>.
          </p>
        </div>
        <form className="ep-login-frm" onSubmit={this.handleSubmit}>
          <h3>Log in to an existing account</h3>
          <div className="ep-login-row">
            <div className="ep-login-item ep-login-label">
              <p>Email</p>
            </div>
            <div className="ep-login-item">
              <input
                id="email"
                className="ep-login-input"
                ref={(input) => { this.email = input; }}
                name="email"
                type="email"
                onChange={this.handleChange}
              />
            </div>
          </div>
          <div className="ep-login-row">
            <div className="ep-login-item ep-login-label">
              <p>Password</p>
            </div>
            <div className="ep-login-item">
              <input
                id="password"
                className="ep-login-input"
                name="password"
                type="password"
                autoComplete="Password"
                ref={(input) => { this.password = input; }}
                onChange={this.handleChange}
              />
            </div>
          </div>
          <div className="ep-login-row">
            <div className="ep-login-item ep-login-label">
              <span style={{ color: 'red' }}>
                {loginError && 'Incorrect email or password, please try again'}
              </span>
            </div>
            <div className="loginBtn">
              <button className="ep-login-btn tpBtn" type="submit" value="submit">{loading ? <Spinner /> : 'LOGIN'}</button>
            </div>
          </div>
        </form>
        <div className="forgot-pwd-button">
          <ForgotPwdButton />
        </div>
      </div>
    );
  }
}

export default Login;
