import React, { Component } from 'react';
import { InfoModal } from '.';


class ExpiredSessionModal extends Component {
  componentDidMount = () => {
    setTimeout(() => {
      window.location.href = '/';
    }, 4000);
  }

  handleClose = () => {
    window.location.href = '/';
  };

  render() {
    return (
      <div className="tpModalContent">
        <InfoModal
          header="Session Expired"
          message="You will be redirected to Login."
          onClose={this.handleClose}
        />
      </div>
    );
  }
}

export default ExpiredSessionModal;
