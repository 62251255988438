import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch, Redirect,
} from 'react-router-dom';
import { readCookie } from './helpers';
import { Header, Footer } from './components';

import {
  Login,
  Licenses,
  NotFound,
  TempLicense,
  ResetPassword,
  CaseSupport,
  SetPassword,
} from './pages';

class App extends Component {
  constructor() {
    super();
    const cookieValue = readCookie('jwt');
    const loginStatus = (cookieValue !== '0');

    this.state = {
      isAuthenticated: loginStatus,
    };
  }

  handleLoggedInUser = () => {
    this.setState({ isAuthenticated: true });
  }

  handleLogout = () => {
    document.cookie = 'jwt=0';
    document.cookie = 'userName=0';
    this.setState({ isAuthenticated: false });
  }

  render() {
    const { isAuthenticated } = this.state;
    return (
      <Router>
        <div className="ep-body-container">
          <Header
            isAuthenticated={isAuthenticated}
            handleLogout={this.handleLogout}
          />
          <div className="ep-content-container">
            <Switch>
              <Route
                exact
                path="/"
                render={props => (
                  <Login
                    {...props}
                    handleLoggedInUser={this.handleLoggedInUser}
                    handleLogout={this.handleLogout}
                  />
                )}
              />
              <Route
                path="/licenses"
                render={() => (
                  isAuthenticated
                    ? <Licenses />
                    : <Redirect to="/" />
                )}
              />
              <Route
                path="/casesupport"
                render={() => (
                  isAuthenticated
                    ? <CaseSupport />
                    : <Redirect to="/" />
                )}
              />
              <Route
                path="/templicense/:accId/:userID?"
                component={TempLicense}
              />
              <Route
                path="/resetPassword"
                render={props => (
                  <ResetPassword
                    {...props}  
                  />
                )}
              />
              <Route
                path="/setPassword/:email?"
                component={SetPassword}
              />
              <Route
                path="*"
                component={NotFound}
              />
            </Switch>
          </div>
          <Footer />
        </div>
      </Router>
    );
  }
}

export default App;
