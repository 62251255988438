import React, { PureComponent } from 'react';
import ModalComp from './ModalComp';

export default class ForgotPwdButton extends PureComponent {
  state = {
    showModal: false,
  }

  openModal = () => {
    this.setState({ showModal: true });
  }

  closeModal = () => {
    this.setState({ showModal: false });
  }

  render() {
    const { showModal } = this.state;
    return (
      <div>
        <ModalComp
          showModal={showModal}
          closeModal={this.closeModal}
          whichModal="ForgotPwdModal"
        />
        <div className="fpb">
          <button
            style={{
              background: '#ffffff', border: '0px', color: '#6172b6', cursor: 'pointer',
            }}
            type="button"
            onClick={this.openModal}
          >
            Forgot Password
          </button>
        </div>
      </div>
    );
  }
}
