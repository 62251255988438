import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { CopyToClipboard } from 'react-copy-to-clipboard';


class SuccessModal extends PureComponent {
  static propTypes = {
    header: PropTypes.string.isRequired,
    licenseKey: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    handleCopy: PropTypes.func.isRequired,
    buttonText: PropTypes.string.isRequired,
  };

  render() {
    const {
      header, licenseKey, onClose, handleCopy, buttonText,
    } = this.props;

    return (
      <div>
        <h1>{header}</h1>
        <div className="ep-stripe-modal" />
        <span />
        <form className="formContainer">
          <div className="formLine">
            <div className="tpCatLbl">
              <p>License Key:</p>
            </div>
            <div className="tpCatDesc">
              <p>{licenseKey}</p>
            </div>
          </div>
          <div className="formLine">
            <div className="tpCatDesc" style={{ textAlign: 'center' }}>
              <h4>Your license has been emailed to you</h4>
            </div>
          </div>
          <div className="tpBtnsRow">
            <div className="tpModalBtn">
              <CopyToClipboard
                text={licenseKey}
              >
                <button
                  type="button"
                  className="tpBtn"
                  onClick={handleCopy}
                >
                  {buttonText}
                </button>
              </CopyToClipboard>
            </div>
            <div className="tpModalBtn">
              <button
                className="tpBtn"
                type="button"
                onClick={onClose}
              >
                OK
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default SuccessModal;
