import React, { PureComponent } from 'react';

import './NotFound.css';

export default class NotFound extends PureComponent {
  render() {
    return (
      <div className="nfDiv">
        <h2>Welcome to GreenHouse</h2>
        <h3>Page not Found</h3>
        <a href="/">Back to Login...</a>
      </div>
    );
  }
}
