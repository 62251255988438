import React, { PureComponent } from 'react';
import { callLambda } from '../helpers';
import { Loading } from '../components';

class SetPassword extends PureComponent {
    state = {
        emailAddress: '',
        response:'',
        responseMessage: '',
    }

    componentDidMount = async () => {
      const { match } = this.props;
      
      if (match.params.email === undefined) {
        this.setState({ emailAddress: '' });
        } else {
            const email = decodeURIComponent(match.params.email);
            this.setState({ emailAddress: email });
      };
    }

    handleChange = (event) => {
      this.setState({
        [event.target.name]: event.target.value,
      });
    }
  
  submitEmailAddress = async (e) => {
    e.preventDefault();
    const { emailAddress } = this.state;

    const data = {
      emailAddress,
      location: window.location.origin + '/',
      isReset: false,
    };

    this.setState({ isFetching: true });
  
    try {
      await callLambda('post', 'forgotPassword', data);
      this.setState({ isFetching: false, response: 'success' });
    } catch (error) {
      if (error.response.data.error === 'Invalid Email Address') {
        this.setState({ responseMessage: 'Please Enter a Valid Email Address and Try Again.' });
      } else {
        this.setState({ responseMessage: 'Please Contact Eggplant Support.' });
      }
      this.setState({ isFetching: false, response: 'serverError' });
    }
  }

  render() {
    const {
      emailAddress, matchError, response, isFetching, responseMessage,
    } = this.state;

    let content;
    if (response === 'serverError') {
      content = (
        <div>
          <h1>Server Error</h1>
          <div className="ep-stripe-modal" />
          <span />
          <form className="formContainer">
            <div className="formLine">
              <p>{responseMessage}</p>
            </div>
          </form>
        </div>
      );
    } else if (response === 'success') {
      content = (
        <div>
          <h1>Email confirmation successful.</h1>
          <div className="ep-stripe-modal" />
          <span />
          <form className="formContainer">
            <div className="formLine">
              <p>Please check your email to create a password.</p>
            </div>
          </form>
        </div>
      );
    } else {
      content = (
        <React.Fragment>
          <h1 style={{ textAlign: 'center' }}>Welcome to Eggplant!</h1>
          <p style={{ textAlign: 'center' }}>Please Confirm your email to set a password on this account:</p>
          <form className="formContainer" onSubmit={this.submitEmailAddress}>
            <div className="formLine-email">
              <div className="tpCatLbl">
                <p>Email Address:</p>
              </div>
                <input
                  className="email-input"
                  type="email"
                  name="emailAddress"
                  value={emailAddress}
                  required
                  minLength={6}
                  onChange={this.handleChange}
                />
              </div>
            <div style={{ textAlign: 'center' }}>
              <span style={{ color: 'red', visibility: matchError ? 'visible' : 'hidden' }}>
                The Email address you've entered doesn't match our records. Please contact Eggplant Support. 
              </span>
            </div>
            <div className="tpBtnsRow">
              <div className="tpModalBtn">
                <button
                  className="tpBtn"
                  type="submit"
                >
                  Confirm Email
                </button>
              </div>
            </div>
          </form>
        </React.Fragment>
      );
    }

    return (
      <div style={{ margin: '0 400px' }}>
        {!isFetching
          ? (
            <React.Fragment>
              { content }
            </React.Fragment>
          ) : (
            <Loading />
          )}
      </div>
    );
  }
}

export default SetPassword;