/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { PureComponent } from 'react';
import './ModalComp.css';
import PropTypes from 'prop-types';
import ModalCompHOC from './ModalCompHOC';

class ModalComp extends PureComponent {
  static propTypes = {
    closeModal: PropTypes.func.isRequired,
    showModal: PropTypes.bool.isRequired,
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]).isRequired,
  };

  componentDidUpdate() {
    const { showModal } = this.props;
    if (showModal) {
      document.addEventListener('keydown', this.escFunction, false);
    } else {
      document.removeEventListener('keydown', this.escFunction, false);
    }
  }

  escFunction = (e) => {
    const { closeModal } = this.props;
    if (e.keyCode === 27) {
      closeModal();
    }
  };

  clickOutsideToClose = (e) => {
    const { closeModal } = this.props;
    if (e.target === e.currentTarget) {
      closeModal();
    }
  }

  render() {
    const { showModal, children } = this.props;
    return (
      <div
        className={showModal ? 'modalContainer' : 'modalOff'}
        onClick={this.clickOutsideToClose}
      >
        <div className="infoContainer">
          {children}
        </div>
      </div>
    );
  }
}

export default ModalCompHOC(ModalComp);
