import { API } from 'aws-amplify';
import moment from 'moment';

export const readCookie = (cookieName) => {
  const cookieString = document.cookie;
  const cookieArray = cookieString.split('; ');

  const [cookieValue] = cookieArray
    .filter(cookie => cookie.split('=')[0] === cookieName)
    .map(cookie => cookie.split('=')[1]);
  return cookieValue;
};

export const formatDate = myDate => (
  myDate !== 'non-expiring'
    ? moment.utc(myDate).format('DD-MMM-YYYY')
    : myDate
);

export const sortDate = (a, b) => {
  if (a === b) return 0;
  const aReverse = a.split('-').reverse().join('');
  const bReverse = b.split('-').reverse().join('');
  let aFormatted;
  let bFormatted;
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May',
    'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  months.forEach((item, index) => {
    const formattedIndex = index < 9 ? `0${index + 1}` : index + 1;
    if (aReverse.includes(item)) {
      aFormatted = aReverse.replace(item, formattedIndex);
    }
    if (bReverse.includes(item)) {
      bFormatted = bReverse.replace(item, formattedIndex);
    }
  });
  return aFormatted > bFormatted ? 1 : -1;
};

export const callLambda = async (requestType, route, data = {}, jwtToken = readCookie('jwt')) => {
  const options = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
    body: {
      ...data,
    },
  };

  return API[requestType]('greenhouse', `/${route}`, options);
};

export const createKey = () => {
  const myKey = Math.random()
    .toString(30)
    .substr(2, 12)
    .toUpperCase();

  return myKey;
};

export const assertTokenAlive = (decoded) => {
  const now = Date.now().valueOf() / 1000;
  if (typeof decoded.exp === 'undefined') {
    throw new Error(`token has no expiration: ${JSON.stringify(decoded)}`);
  }
  if (decoded.exp < now) {
    throw new Error(`token expired: ${JSON.stringify(decoded)}`);
  }
  if (typeof decoded.nbf !== 'undefined' && decoded.nbf > now) {
    throw new Error(`token not yet valid: ${JSON.stringify(decoded)}`);
  }
};
