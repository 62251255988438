import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';


class EmailDetails extends PureComponent {
  static propTypes = {
    emails: PropTypes.array,
  };

  render() {
    const { emails } = this.props;

    const emailContent = emails.map(row => (
      <div
        key={row.fileKey}
        style={{
          display: 'flex',
          backgroundColor: '#dbc8ff',
          height: 370,
          marginTop: 5,
          flexDirection: 'column',
          borderRadius: '5px',
        }}
      >
        <div style={{ display: 'flex', marginTop: 10, marginLeft: '10px' }}>
          <span className="tpCatLbl" style={{ paddingRight: 40 }}>Date: </span>
          <span>{row.CreatedDate}</span>
        </div>
        <div style={{ display: 'flex', marginTop: 5, marginLeft: '10px' }}>
          <span className="tpCatLbl" style={{ paddingRight: 40 }}>From: </span>
          <span>{row.FromName}</span>
        </div>
        <div style={{ display: 'flex', marginTop: 5, marginLeft: '10px' }}>
          <span className="tpCatLbl" style={{ paddingRight: 40 }}>To: </span>
          <span>{row.ToAddress}</span>
        </div>
        <div style={{ display: 'flex', marginTop: 5, marginLeft: '10px' }}>
          <span className="tpCatLbl" style={{ paddingRight: 40 }}>Subject: </span>
          <span>{row.Subject}</span>
        </div>
        <div style={{ marginTop: 5, marginLeft: '10px' }}>
          <span className="tpCatLbl">Text: </span>
          <p style={{
            whiteSpace: 'pre-wrap',
            overflow: 'scroll',
            height: 200,
            paddingLeft: 10,
            backgroundColor: '#f1eff4',
            width: '95%',
          }}
          >
            {row.TextBody}
          </p>
        </div>
      </div>
    ));

    return (
      <div className="emailsTable">
        {emailContent}
      </div>
    );
  }
}

export default EmailDetails;
