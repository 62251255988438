import React, { PureComponent } from 'react';
import './licenses.css';

class Licenses extends PureComponent {
  render() {
    return (
      <div className="ep-lic-container" style={{ width: '100vw' }}>
        <div className="ep-lic-verbiage">
          <h2 style={{ marginLeft: '0px' }}>Licenses</h2>
          <p>
            Eggplant licenses are no longer managed from Greenhouse.
            <br />
            <br />
            Your licenses can now be found at the Keysight Enterprise License Management 
            System (KSM) - <a className="redlink" href="https://www.keysight.com/find/softwaremanager" target="_blank" rel="noopener noreferrer">https://www.keysight.com/find/softwaremanager</a>
            <br />
            <br />
            If you don't already have an account, you will be able to create one within KSM.
            <br />
            <br />
            Please contact <a class="redlink" href="mailto:eggplant.support@keysight.com">eggplant.support@keysight.com</a> if you need any assistance.
          </p>
        </div>
      </div>
    );
  }
}

export default Licenses;
