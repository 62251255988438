import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import './AttachedFiles.css';


class AttachedFiles extends PureComponent {
  static propTypes = {
    handleDelete: PropTypes.func,
    attachments: PropTypes.array,
  };

  deleteAttachment = (e) => {
    const { handleDelete } = this.props;
    handleDelete(e.target.id);
  };

  render() {
    const { attachments, handleDelete } = this.props;

    const attachmentContent = attachments.map(row => (
      <div
        key={row.fileKey}
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          backgroundColor: '#dbc8ff',
          alignItems: 'center',
          height: 35,
          marginTop: 5,
          borderRadius: '5px',
          width: 450,
        }}
      >
        <p style={{ marginLeft: 10 }}>
          {row.name.length > 45
            ? `${row.name.substr(0, 30)}...${row.name.substr(row.name.length - 8, 8)}`
            : row.name
          }
        </p>
        {handleDelete
          && (
            <button
              className="tpBtnAtt"
              type="button"
              onClick={this.deleteAttachment}
              id={row.fileKey}
              style={{
                alignSelf: 'center', position: 'relative', marginRight: 10,
              }}
            >
              x
            </button>
          )}
      </div>
    ));

    return (
      <div style={{ marginLeft: 10 }}>
        {attachmentContent}
      </div>
    );
  }
}

export default AttachedFiles;
