import React, { PureComponent } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { map, defaultTo } from 'ramda';
import { callLambda, formatDate, createKey } from '../../helpers';
import {
  Loading,
  Spinner,
  AttachedFiles,
  EmailDetails,
} from '..';
import { InfoModal, ExpiredSessionModal } from '.';
import { INITIAL, EXPIRED, SERVER_ERROR } from './modalStates';


class CaseDetailModal extends PureComponent {
  static propTypes = {
    closeModal: PropTypes.func.isRequired,
    pickListValues: PropTypes.object.isRequired,
    selectedCaseData: PropTypes.object,
  };

  state = {
    isFetching: true,
    caseDetailData: {},
    newComment: '',
    modalState: INITIAL,
    addingComment: false,
    caseAttachmentsData: [],
    caseEmailsData: [],
  };

  componentDidMount = async () => {
    await this.fetchCaseDetail();
  };

  handleClose = () => {
    const { closeModal } = this.props;
    this.setState({ modalState: INITIAL });
    closeModal();
  };

  createAttachmentDataForDisplay = (attachments) => {
    const caseAttachmentList = attachments.map((attachment) => {
      const fileKey = createKey();
      return {
        fileKey,
        name: attachment.Name,
      };
    });
    return caseAttachmentList;
  };

  createEmailDataForDisplay = (emails) => {
    const sortedEmails = emails.sort((a, b) => moment(b.CreatedDate) - moment(a.CreatedDate));

    const caseEmailList = sortedEmails.map((email) => {
      const fileKey = createKey();
      const localDate = moment.utc(email.CreatedDate).local();
      return {
        fileKey,
        CreatedDate: localDate.format('dddd, MMMM Do YYYY, h:mm a'),
        FromName: email.FromName,
        ToAddress: email.ToAddress,
        Subject: email.Subject,
        TextBody: email.TextBody,
      };
    });
    return caseEmailList;
  };

  fetchCaseDetail = async () => {
    const { selectedCaseData } = this.props;

    try {
      const result = await callLambda('post', 'getCaseDetail', { selectedCaseData });

      const attachments = this.createAttachmentDataForDisplay(result.attachments);
      const emails = this.createEmailDataForDisplay(result.emails);

      this.setState({
        caseDetailData: result.caseDetail,
        caseAttachmentsData: attachments,
        caseEmailsData: emails,
      });
      this.setModalState(INITIAL);
    } catch (error) {
      if (error.message.includes('401')) {
        this.setModalState(EXPIRED);
      } else {
        this.setModalState(SERVER_ERROR);
      }
    }
  };

  handleChange = (event) => {
    this.setState({ newComment: event.target.value });
  };

  uploadComment = async () => {
    const { selectedCaseData } = this.props;
    const { newComment } = this.state;

    const data = {
      caseId: selectedCaseData.caseId,
      newComment,
    };

    this.setState({ addingComment: true });

    try {
      await callLambda('post', 'updateCaseComment', data);
      await this.fetchCaseDetail();
      this.setState({ addingComment: false, newComment: '' });
    } catch (error) {
      if (error.message.includes('401')) {
        this.setModalState(EXPIRED);
      } else {
        this.setModalState(SERVER_ERROR);
      }
    }
  };

  setModalState = (modal) => {
    this.setState({ isFetching: false, modalState: modal });
  };

  renderCommentHistory = () => {
    const { caseDetailData } = this.state;
    const { comments } = caseDetailData;
    const sortedComments = comments.sort((a, b) => moment(a.commentDate) - moment(b.commentDate));

    return sortedComments.map((comment) => {
      const localDate = moment.utc(comment.commentDate).local();
      return (
        <div key={comment.commentDate}>
          <p style={{ fontStyle: 'italic' }}>
            {localDate.format('dddd, MMMM Do YYYY, h:mm a')}
          </p>
          <p style={{ paddingBottom: 15 }}>{comment.commentBody}</p>
        </div>
      );
    });
  };

  render() {
    const {
      modalState,
      isFetching,
      caseDetailData,
      newComment,
      addingComment,
      caseAttachmentsData,
      caseEmailsData,
    } = this.state;

    const { pickListValues } = this.props;
    const { productPicklist } = pickListValues;
    const productLabelMap = {};
    productPicklist.forEach((item) => {
      productLabelMap[item.value] = item.label;
    });

    const formattedData = map(defaultTo('---'), caseDetailData);

    const {
      caseNumber,
      contactName,
      subject,
      priority,
      dateOpened,
      dateClosed,
      status,
      accountCSM,
      product,
      productVersion,
      eggplantOS,
      sutPlatform,
      vncServer,
      type,
      caseOrigin,
      webEmail,
      webName,
      description,
    } = formattedData;

    let modalContent;

    const formattedDateOpened = dateOpened === '---' ? '---' : formatDate(dateOpened);
    const formattedDateClosed = dateClosed === '---' ? '---' : formatDate(dateClosed);
    const commentHistory = caseDetailData.comments || [];

    if (modalState === INITIAL) {
      modalContent = (
        <div className="tpModalContent">
          <h1>Case Detail</h1>
          <div className="ep-stripe-modal" />
          <span />
          <form
            className="formContainer"
            style={{
              overflow: 'scroll',
              height: 550,
              fontSize: 14,
              wordBreak: 'break-word',
            }}
          >
            <div style={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr 1fr 1fr',
              gridRowGap: 15,
              gridColumnGap: 30,
            }}
            >
              <span className="tpCatLbl">Case Number: </span>
              <span>{caseNumber}</span>
              <span className="tpCatLbl">Contact Name: </span>
              <span>{contactName}</span>
              <span className="tpCatLbl">Date Opened: </span>
              <span>{formattedDateOpened}</span>
              <span className="tpCatLbl">Date Closed: </span>
              <span>{formattedDateClosed}</span>
              <span className="tpCatLbl">Subject: </span>
              <span style={{ gridColumn: '2 / span 3' }}>{subject}</span>
              <span className="tpCatLbl">Status: </span>
              <span style={{ gridColumn: '2 / span 3' }}>{status}</span>
              <span className="tpCatLbl">Type: </span>
              <span style={{ gridColumn: '2 / span 3' }}>{type}</span>
              <span className="tpCatLbl">Priority: </span>
              <span style={{ gridColumn: '2 / span 3' }}>{priority}</span>
              <span className="tpCatLbl">Product: </span>
              <span style={{ gridColumn: '2 / span 3' }}>{productLabelMap[product]}</span>
              <span className="tpCatLbl">Product Version: </span>
              <span>{productVersion}</span>
              <span className="tpCatLbl">SUT Platform: </span>
              <span>{sutPlatform}</span>
              <span className="tpCatLbl">Eggplant OS: </span>
              <span>{eggplantOS}</span>
              <span className="tpCatLbl">VNC Server: </span>
              <span>{vncServer}</span>
              <span className="tpCatLbl">Case Origin: </span>
              <span style={{ gridColumn: '2 / span 3' }}>{caseOrigin}</span>
              <span className="tpCatLbl">Web Name: </span>
              <span style={{ gridColumn: '2 / span 3' }}>{webName}</span>
              <span className="tpCatLbl">Web Email: </span>
              <span style={{ gridColumn: '2 / span 3' }}>{webEmail}</span>
              <span className="tpCatLbl">Account CSM: </span>
              <span>{accountCSM}</span>
            </div>
            <div style={{ fontSize: 14, marginTop: 20 }}>
              <div>
                <span className="tpCatLbl">Description: </span>
                <p style={{ whiteSpace: 'pre-wrap' }}>{description}</p>
              </div>
              <div style={{ marginTop: 20 }}>
                <span className="tpCatLbl">Comments: </span>
                {commentHistory.length !== 0
                  ? this.renderCommentHistory()
                  : (<p>No comments</p>)
                }
                <textarea
                  rows="5"
                  cols="75"
                  name="newComment"
                  value={newComment}
                  onChange={this.handleChange}
                />
                <button
                  className="tpBtn ep-login-btn"
                  type="button"
                  onClick={this.uploadComment}
                >
                  {addingComment ? <Spinner />
                    : 'Add Comment'
                  }
                </button>
              </div>
              <div style={{ marginTop: 20 }}>
                <span className="tpCatLbl">Email History: </span>
                <EmailDetails
                  emails={caseEmailsData}
                />
              </div>
              <div style={{
                display: 'flex',
                marginTop: 20,
                marginBottom: 10,
                fontSize: 14,
              }}
              >
                <div className="tpCatLbl">
                  <p>Attachments:</p>
                </div>
                <div className="tpCatDesc">
                  <AttachedFiles
                    attachments={caseAttachmentsData}
                  />
                </div>
              </div>
            </div>
          </form>
          <div className="tpBtnsRow">
            <div className="tpModalBtn">
              <button
                className="tpBtn"
                style={{ position: 'relative', bottom: '10px' }}
                type="button"
                onClick={this.handleClose}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      );
    } else if (modalState === EXPIRED) {
      modalContent = (
        <ExpiredSessionModal />
      );
    } else if (modalState === SERVER_ERROR) {
      modalContent = (
        <InfoModal
          header="Something Went Wrong"
          message="Please retry or contact support."
          onClose={this.handleClose}
          email="support@eggplantsoftware.com"
        />
      );
    }

    return (
      <div className="tpModalContent">
        {!isFetching
          ? (
            <div className="tpModalContent">
              {modalContent}
            </div>
          ) : (
            <Loading />
          )}
      </div>
    );
  }
}

export default CaseDetailModal;
