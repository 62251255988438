import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';


class InfoModal extends PureComponent {
  static propTypes = {
    header: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    //email: PropTypes.string,
  };

  render() {
    const { header, message, onClose } = this.props;

    return (
      <div>
        <h1>{header}</h1>
        <div className="ep-stripe-modal" />
        <span />
        <form className="formContainer">
          <div className="formLine">
            <div className="tpCatDesc">
              <p>{message}</p>
            </div>
            {}
          </div>
          <div className="tpBtnsRow">
            <div className="tpModalBtn">
              <button
                className="tpBtn"
                type="button"
                onClick={onClose}
              >
                OK
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default InfoModal;
