import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Loading } from '..';
import { InfoModal } from '.';
import { callLambda } from '../../helpers';
import { INITIAL, SUCCESS, SERVER_ERROR } from './modalStates';


export default class ForgotPwdModal extends PureComponent {
  static propTypes = {
    closeModal: PropTypes.func.isRequired,
  }

  state = {
    emailAddress: '',
    isFetching: false,
    modalState: INITIAL,
  }

  handleChange = (event) => {
    this.setState({ emailAddress: event.target.value });
  }

  handleClose = () => {
    const { closeModal } = this.props;
    this.setState({ modalState: INITIAL, emailAddress: '' });
    closeModal();
  }

  submitEmailAddress = async (e) => {
    e.preventDefault();
    const { emailAddress } = this.state;

    const data = {
      emailAddress,
      location: window.location.href,
      isReset: true,
    };

    this.setState({ isFetching: true });

    try {
      await callLambda('post', 'forgotPassword', data);
      this.setModalState(SUCCESS);
    } catch (error) {
      this.setModalState(SERVER_ERROR);
    }
  }

  setModalState = (modal) => {
    this.setState({ isFetching: false, modalState: modal });
  }

  render() {
    const { emailAddress, modalState, isFetching } = this.state;

    let modalContent;

    if (modalState === INITIAL) {
      modalContent = (
        <React.Fragment>
          <h1>Forgot Password</h1>
          <div className="ep-stripe-modal" />
          <span />
          <form className="formContainer" onSubmit={this.submitEmailAddress}>
            <div className="formLine">
              <div className="tpCatLbl">
                <p>Email:</p>
              </div>
              <div className="tpCatDesc">
                <input
                  style={{ marginLeft: '0px', width: '100%' }}
                  type="email"
                  name="modalForgotEmail"
                  placeholder="Email Address"
                  value={emailAddress}
                  onChange={this.handleChange}
                />
              </div>
            </div>
            <div className="tpBtnsRow">
              <div className="tpModalBtn">
                <button
                  className="tpBtn"
                  type="submit"
                >
                  Submit
                </button>
              </div>
              <div className="tpModalBtn">
                <button
                  className="tpBtn"
                  type="button"
                  onClick={this.handleClose}
                >
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </React.Fragment>
      );
    } else if (modalState === SUCCESS) {
      modalContent = (
        <InfoModal
          header="Link Sent"
          message="You will receive an email with a link to reset your password."
          onClose={this.handleClose}
        />
      );
    } else if (modalState === SERVER_ERROR) {
      modalContent = (
        <InfoModal
          header="Error"
          message="This email address cannot be verified."
          onClose={this.handleClose}
        />
      );
    }

    return (
      <div className="tpModalContent">
        {!isFetching
          ? (
            <div className="tpModalContent">
              {modalContent}
            </div>
          ) : (
            <Loading />
          )}
      </div>
    );
  }
}
