import React, { PureComponent } from 'react';
import {
  ChangePwdModal,
  ForgotPwdModal,
  CopyToClipboardModal,
  GenerateLicenseModal,
  TransferLicenseModal,
  ExpiredSessionModal,
  EmailAllModal,
  NewCaseModal,
  CaseDetailModal,
  TimeoutModal,
} from './modals';

export default (ChildComponent) => {
  class ModalHOC extends PureComponent {
    state = {
      componentToPass: '',
    };

    whichComponent = () => {
      const {
        whichModal, licensedServerId, showModal, closeModal, fetchLicenseData,
        product, serialNumber, hostID, userName, expireDate, licenseKey, fetchExistingCases,
        isFloating, renewing, licensedServers, selectedCaseData, pickListValues,
      } = this.props;
      switch (whichModal) {
        case 'CopyToClipboardModal':
          return this.setState({
            componentToPass:
              (<CopyToClipboardModal
                closeModal={closeModal}
                licenseKey={licenseKey}
                expireDate={expireDate}
                licensedServerId={licensedServerId}
                userName={userName}
                hostID={hostID}
              />),
          });
        case 'EmailAllModal':
          return this.setState({
            componentToPass:
              (<EmailAllModal
                showModal={showModal}
                closeModal={closeModal}
                licensedServers={licensedServers}
              />),
          });
        case 'ChangePwdModal':
          return this.setState({
            componentToPass:
              (<ChangePwdModal
                closeModal={closeModal}
              />),
          });
        case 'ForgotPwdModal':
          return this.setState({
            componentToPass:
              (<ForgotPwdModal
                closeModal={closeModal}
              />),
          });
        case 'GenerateLicenseModal':
          return this.setState({
            componentToPass:
              (<GenerateLicenseModal
                closeModal={closeModal}
                userName={userName}
                serialNumber={serialNumber}
                product={product}
                expireDate={expireDate}
                licensedServerId={licensedServerId}
                fetchLicenseData={fetchLicenseData}
                isFloating={isFloating}
                renewing={renewing}
              />),
          });
        case 'TransferLicenseModal':
          return this.setState({
            componentToPass: (
              <TransferLicenseModal
                closeModal={closeModal}
                userName={userName}
                serialNumber={serialNumber}
                startingHostID={hostID}
                product={product}
                expireDate={expireDate}
                licensedServerId={licensedServerId}
                fetchLicenseData={fetchLicenseData}
                showModal={showModal}
                isFloating={isFloating}
                renewing={renewing}
              />
            ),
          });
        case 'CaseDetailModal':
          return this.setState({
            componentToPass:
              (<CaseDetailModal
                showModal={showModal}
                closeModal={closeModal}
                pickListValues={pickListValues}
                selectedCaseData={selectedCaseData}
              />),
          });
        case 'NewCaseModal':
          return this.setState({
            componentToPass:
              (<NewCaseModal
                showModal={showModal}
                closeModal={closeModal}
                pickListValues={pickListValues}
                fetchExistingCases={fetchExistingCases}
              />),
          });
        case 'ExpiredSessionModal':
          return this.setState({
            componentToPass:
              (<ExpiredSessionModal />),
          });
        case 'TimeoutModal':
          return this.setState({
            componentToPass:
            (<TimeoutModal />),
          });
        default:
          return this.setState({ componentToPass: <ForgotPwdModal closeModal={closeModal} /> });
      }
    };

    componentDidUpdate = (prevProps) => {
      const { showModal } = this.props;
      if (prevProps.showModal !== showModal) {
        this.whichComponent();
      }
    };

    render() {
      const { componentToPass } = this.state;
      return (
        <ChildComponent {...this.props}>
          {componentToPass}
        </ChildComponent>
      );
    }
  }
  return ModalHOC;
};
