import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { callLambda, createKey } from '../../helpers';
import { Loading, AttachedFiles } from '..';
import { InfoModal, ExpiredSessionModal } from '.';
import {
  INITIAL, SUCCESS, EXPIRED, SERVER_ERROR,
} from './modalStates';


class NewCaseModal extends PureComponent {
  static propTypes = {
    closeModal: PropTypes.func.isRequired,
    pickListValues: PropTypes.object.isRequired,
    fetchExistingCases: PropTypes.func.isRequired,
  }

  state = {
    isFetching: false,
    modalState: INITIAL,
    subject: '',
    product: '',
    productVersion: '',
    description: '',
    type: '',
    priority: '',
    vncServer: '',
    eggplantOS: '',
    sutPlatform: '',
    attachments: [],
    uploadError: false,
  }

  handleClose = () => {
    const { closeModal } = this.props;
    this.setState({ modalState: INITIAL });
    closeModal();
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
      uploadError: false,
    });
  }

  handleClearWarning = () => {
    this.setState({
      uploadError: false,
    });
  }

  submitNewCase = async (e) => {
    e.preventDefault();
    const { fetchExistingCases } = this.props;
    const {
      subject,
      product,
      productVersion,
      description,
      type,
      priority,
      vncServer,
      eggplantOS,
      sutPlatform,
      attachments,
    } = this.state;

    const caseDetails = {
      subject,
      product,
      productVersion,
      description,
      type,
      priority,
      vncServer,
      eggplantOS,
      sutPlatform,
    };

    const data = {
      caseDetails,
      attachments,
    };

    this.setState({ isFetching: true });

    try {
      await callLambda('post', 'submitNewCase', data);

      fetchExistingCases();
      this.setModalState(SUCCESS);
    } catch (error) {
      if (error.message.includes('401')) {
        this.setModalState(EXPIRED);
      } else {
        this.setModalState(SERVER_ERROR);
      }
    }
  }

  setModalState = (modal) => {
    this.setState({ isFetching: false, modalState: modal });
  }

  displayPicklistLabelsAndValues = (list) => {
    const { pickListValues } = this.props;
    const labelsAndValues = pickListValues[list];

    return labelsAndValues.map(item => (<option value={item.value} label={item.label} key={item.value}>{item.label}</option>));
  }

  displayPicklistValues = (list) => {
    const { pickListValues } = this.props;
    const values = pickListValues[list];

    return values.map(value => (
      <option value={value} label={value} key={value}>{value}</option>));
  }

  addAttachment = async (event) => {
    const { attachments } = this.state;
    const file = event.target.files[0];

    // reset target value allowing the same file to be re-added after deletion
    // eslint-disable-next-line no-param-reassign
    event.target.value = '';
    const { name, size } = file;
    const fileKey = createKey();

    if (size > 4900000) {
      this.setState({ uploadError: true });
    } else {
      this.setState({ uploadError: false });
      const base64file = await this.getBase64(file);

      const fileObject = {
        name,
        size,
        fileKey,
        base64file,
      };
      this.setState({ attachments: [...attachments, fileObject] });
    }
  }

  getBase64 = file => (
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    })
  )

  deleteAttachment = (e) => {
    const { attachments } = this.state;
    const filteredAttachments = attachments.filter(attachment => (attachment.fileKey !== e));
    this.setState({ attachments: filteredAttachments });
  };

  render() {
    const {
      modalState,
      isFetching,
      subject,
      productVersion,
      description,
      attachments,
      uploadError,
    } = this.state;

    const hitAttachmentLimit = attachments.length >= 5;

    let modalContent;

    if (modalState === INITIAL) {
      modalContent = (
        <div className="tpModalContent">
          <h1>Submit New Case</h1>
          <div className="ep-stripe-modal" />
          <span />
          <form
            className="formContainer"
            onSubmit={this.submitNewCase}
            style={{
              // overflow: 'scroll',
              height: 550,
              fontSize: 14,
              wordBreak: 'break-word',
            }}
          >
            <div
              className="thisContainer"
              style={{
                overflow: 'scroll',
              }}
            >
              <div style={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr 1fr 1fr',
                gridRowGap: 25,
                gridColumnGap: 25,
                marginTop: 10,
              }}
              >
                <span className="tpCatLbl">Subject:</span>
                <input
                  style={{ gridColumn: '2 / span 3', width: '95%' }}
                  name="subject"
                  value={subject}
                  type="text"
                  required
                  onChange={this.handleChange}
                />
                <span className="tpCatLbl">Type:</span>
                <select
                  style={{ gridColumn: '2 / span 3', width: '96%' }}
                  name="type"
                  defaultValue="default"
                  onChange={this.handleChange}
                >
                  <option value="default" disabled="disabled">---</option>
                  {this.displayPicklistValues('caseTypePicklist')}
                </select>
                <span className="tpCatLbl">Priority:</span>
                <select
                  style={{ gridColumn: '2 / span 3', width: '96%' }}
                  name="priority"
                  required
                  onChange={this.handleChange}
                >
                  <option value="">---</option>
                  {this.displayPicklistValues('priorityPicklist')}
                </select>
                <span className="tpCatLbl">Product:</span>
                <select
                  style={{ gridColumn: '2 / span 3', width: '96%' }}
                  name="product"
                  required
                  onChange={this.handleChange}
                >
                  <option value="">---</option>
                  {this.displayPicklistLabelsAndValues('productPicklist')}
                </select>
                <span className="tpCatLbl">
                  Product Version:
                </span>
                <input
                  style={{ gridColumn: '2 / span 3' }}
                  name="productVersion"
                  value={productVersion}
                  type="text"
                  onChange={this.handleChange}
                />
                <span className="tpCatLbl">VNC Server:</span>
                <select
                  name="vncServer"
                  defaultValue="default"
                  onChange={this.handleChange}
                >
                  <option value="default" disabled="disabled">---</option>
                  {this.displayPicklistValues('vncServerPicklist')}
                </select>
                <span className="tpCatLbl">Eggplant OS:</span>
                <select
                  name="eggplantOS"
                  defaultValue="default"
                  onChange={this.handleChange}
                  style={{ width: '90%' }}
                >
                  <option value="default" disabled="disabled">---</option>
                  {this.displayPicklistValues('osPicklist')}
                </select>
                <span className="tpCatLbl">SUT Platform:</span>
                <select
                  style={{ gridColumn: '2 / span 3', width: '96%' }}
                  name="sutPlatform"
                  defaultValue="default"
                  onChange={this.handleChange}
                >
                  <option value="default" disabled="disabled">---</option>
                  {this.displayPicklistValues('sutPlatformPicklist')}
                </select>
                <span className="tpCatLbl">Description:</span>
                <textarea
                  rows="6"
                  cols="70"
                  style={{
                    gridColumn: '2 / span 3',
                    border: '1px solid #d8d8d8',
                  }}
                  name="description"
                  value={description}
                  onChange={this.handleChange}
                />
              </div>
              <div style={{
                display: 'flex',
                marginTop: 5,
                marginBottom: 10,
                fontSize: 14,
              }}
              >
                <div className="tpCatLbl">
                  <p>Attachments:</p>
                </div>
                <div className="tpCatDesc">
                  <AttachedFiles attachments={attachments} handleDelete={this.deleteAttachment} />
                </div>
              </div>
              <div style={{ display: 'flex', marginBottom: 20, fontSize: 14 }}>
                <div className="tpCatLbl">
                  <p />
                </div>
                <div className="tpCatDesc">
                  <span style={{
                    color: 'red',
                    marginLeft: 10,
                    marginBotton: 10,
                  }}
                  >
                    {uploadError && 'File size too large to upload'}
                  </span>
                </div>
              </div>
            </div>
            <div className="tpBtnsRow">
              <div className="tpModalBtn">
                <label
                  className="tpBtn"
                  id="label"
                  htmlFor="file"
                  onClick={this.handleClearWarning}
                  style={{
                    backgroundColor: hitAttachmentLimit && '#b1b9db',
                    cursor: hitAttachmentLimit ? 'auto' : 'pointer',
                    lineHeight: 2.5,
                  }}
                >
                  Upload File
                  <input
                    type="file"
                    name="file"
                    id="file"
                    className="inputfile"
                    onChange={this.addAttachment}
                    disabled={hitAttachmentLimit}
                  />
                </label>
              </div>
              <div className="tpModalBtn">
                <button
                  className="tpBtn"
                  type="submit"
                >
                  Submit
                </button>
              </div>
              <div className="tpModalBtn">
                <button
                  className="tpBtn"
                  type="button"
                  onClick={this.handleClose}
                >
                  Close
                </button>
              </div>
            </div>
          </form>
        </div>
      );
    } else if (modalState === SUCCESS) {
      modalContent = (
        <InfoModal
          header="New Case Submitted"
          message="Your case has been successfully submitted."
          onClose={this.handleClose}
        />
      );
    } else if (modalState === EXPIRED) {
      modalContent = (
        <ExpiredSessionModal />
      );
    } else if (modalState === SERVER_ERROR) {
      modalContent = (
        <InfoModal
          header="Something went wrong"
          message="Please retry or contact support."
          onClose={this.handleClose}
          email="support@eggplantsoftware.com"
        />
      );
    }

    return (
      <div className="tpModalContent">
        {!isFetching
          ? (
            <div className="tpModalContent">
              {modalContent}
            </div>
          ) : (
            <Loading />
          )}
      </div>
    );
  }
}

export default NewCaseModal;
