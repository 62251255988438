import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ModalComp from './ModalComp';

export default class KeyButton extends PureComponent {
  static propTypes = {
    myKey: PropTypes.string.isRequired,
    file: PropTypes.string,
    expireDate: PropTypes.string,
    licensedServerId: PropTypes.string,
    userName: PropTypes.string,
    hostID: PropTypes.string,
  };

  state = {
    showModal: false,
  }

  openModal = () => {
    this.setState({ showModal: true });
  }

  closeModal = () => {
    this.setState({ showModal: false });
  }

  render() {
    const { showModal } = this.state;
    const {
      myKey, file, expireDate, licensedServerId, userName, hostID,
    } = this.props;

    const downloadFile = myKey.startsWith('http') ? myKey : file;

    return (
      <div>
        {myKey === '- license file -' || myKey.startsWith('http') ? (
          <a href={downloadFile} download>
            <button
              type="button"
              style={{
                background: '#50afab',
                border: '0px',
                width: '80%',
                lineHeight: 1.8,
                textAlign: 'center',
              }}
              className="offBtn"
            >
              Download
            </button>
          </a>
        ) : (
          <React.Fragment>
            <ModalComp
              showModal={showModal}
              licenseKey={myKey}
              closeModal={this.closeModal}
              whichModal="CopyToClipboardModal"
              expireDate={expireDate}
              userName={userName}
              licensedServerId={licensedServerId}
              hostID={hostID}
            />
            <button
              style={{ background: '#50afab', border: '0px' }}
              type="button"
              className="offBtn"
              onClick={this.openModal}
            >
              {myKey.substr(0, 9)}
              ...
            </button>
          </React.Fragment>
        )}
      </div>
    );
  }
}
