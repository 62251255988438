import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import './menu.css';
import ModalComp from './ModalComp';

class Menu extends PureComponent {
  static propTypes = {
    handleLogout: PropTypes.func.isRequired,
    history: PropTypes.object,
  }

  state = {
    showModal: false,
  }

  openModal = () => {
    this.setState({ showModal: true });
  }

  closeModal = () => {
    this.setState({ showModal: false });
  }

  render() {
    const { showModal } = this.state;
    const { history, handleLogout } = this.props;
    return (
      <div className="ep-menu">
        <ModalComp
          showModal={showModal}
          closeModal={this.closeModal}
          whichModal="ChangePwdModal"
        />
        <div className="ep-menu-item">
          <button
            style={{
              background: '#ffffff', border: '0px', color: '#6172b6', cursor: 'pointer',
            }}
            type="button"
            onClick={() => history.push('/licenses')}
          >
            Licenses
          </button>
        </div>
        <div className="ep-menu-item">
          <button
            style={{
              background: '#ffffff', border: '0px', color: '#6172b6', cursor: 'pointer',
            }}
            type="button"
            onClick={() => history.push('/casesupport')}
          >
            Case Support
          </button>
        </div>
        <div className="ep-menu-item">
          <button
            style={{
              background: '#ffffff', border: '0px', color: '#6172b6', cursor: 'pointer',
            }}
            type="button"
            onClick={this.openModal}
          >
            Change Password
          </button>
        </div>
        <div className="ep-menu-item">
          <button
            style={{
              background: '#ffffff', border: '0px', color: '#6172b6', cursor: 'pointer',
            }}
            type="button"
            onClick={handleLogout}
          >
            Sign Out
          </button>
        </div>
      </div>
    );
  }
}

export default withRouter(Menu);
