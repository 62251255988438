import React, { PureComponent } from 'react';
import loading from '../assets/loader.gif';

export default class Loading extends PureComponent {
  render() {
    return (
      <div className="ep-container">
        <div className="loadingContainer">
          <img src={loading} alt="" />
        </div>
      </div>
    );
  }
}
