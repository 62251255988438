import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { formatDate, callLambda } from '../../helpers';
import { Loading } from '..';
import { InfoModal, ExpiredSessionModal } from '.';
import {
  INITIAL, SUCCESS, EXPIRED, SERVER_ERROR,
} from './modalStates';


class CopyToClipboardModal extends PureComponent {
  static propTypes = {
    licenseKey: PropTypes.string,
    closeModal: PropTypes.func.isRequired,
    expireDate: PropTypes.string,
    userName: PropTypes.string,
    hostID: PropTypes.string,
    licensedServerId: PropTypes.string,
  };

  state = {
    buttonText: 'Copy',
    modalState: INITIAL,
    isFetching: false,
  }

  handleClose = () => {
    const { closeModal } = this.props;
    this.setState({ modalState: INITIAL, buttonText: 'Copy' });
    closeModal();
  };

  handleCopy = () => {
    this.setState({ buttonText: 'Copied' });
  }

  sendLicenseEmail = async () => {
    const { licensedServerId } = this.props;

    this.setState({ isFetching: true });

    const data = {
      licensedServers: [licensedServerId],
    };

    try {
      await callLambda('post', 'emailLicense', data);

      this.setModalState(SUCCESS);
    } catch (e) {
      if (e.message.includes('401')) {
        this.setModalState(EXPIRED);
      } else {
        this.setModalState(SERVER_ERROR);
      }
    }
  }

  setModalState = (modal) => {
    this.setState({ isFetching: false, modalState: modal });
  }

  render() {
    const { licenseKey, expireDate, userName, hostID } = this.props;
    const { buttonText, modalState, isFetching } = this.state;

    let modalContent;

    if (modalState === INITIAL) {
      modalContent = (
        <div className="tpModalContent">
          <h1>Copy License key</h1>
          <div className="ep-stripe-modal" />
          <span />
          <form className="formContainer">
            <div className="formLine">
              <div className="tpCatLbl">
                <p>License Key:</p>
              </div>
              <div className="tpCatDesc">{licenseKey}</div>
            </div>
            <div className="formLine">
              <div className="tpCatLbl">
                <p>Username:</p>
              </div>
              <div className="tpCatDesc">{userName}</div>
            </div>
            <div className="formLine">
              <div className="tpCatLbl">
                <p>Host ID:</p>
              </div>
              <div className="tpCatDesc">{hostID}</div>
            </div>
            <div className="formLine">
              <div className="tpCatLbl">
                <p>Expire Date:</p>
              </div>
              <div className="tpCatDesc">
                <p>
                  {formatDate(expireDate)}
                </p>
              </div>
            </div>
            <div className="tpBtnsRow">
              <div className="tpModalBtn">
                <CopyToClipboard
                  text={licenseKey}
                >
                  <button
                    type="button"
                    className="tpBtn"
                    onClick={this.handleCopy}
                  >
                    {buttonText}
                  </button>
                </CopyToClipboard>
              </div>
              <div className="tpModalBtn">
                <button
                  className="tpBtn"
                  type="button"
                  onClick={this.sendLicenseEmail}
                >
                  Email
                </button>
              </div>
              <div className="tpModalBtn">
                <button
                  className="tpBtn"
                  type="button"
                  onClick={this.handleClose}
                >
                  Close
                </button>
              </div>
            </div>
          </form>
        </div>
      );
    } else if (modalState === SUCCESS) {
      modalContent = (
        <InfoModal
          header="License Mailed"
          message="Your license has been emailed to you."
          onClose={this.handleClose}
        />
      );
    } else if (modalState === SERVER_ERROR) {
      modalContent = (
        <InfoModal
          header="Something Went Wrong"
          message="Please retry or contact support."
          onClose={this.handleClose}
          email="support@eggplantsoftware.com"
        />
      );
    } else if (modalState === EXPIRED) {
      modalContent = (
        <ExpiredSessionModal />
      );
    }

    return (
      <div className="tpModalContent">
        {!isFetching
          ? (
            <div className="tpModalContent">
              {modalContent}
            </div>
          ) : (
            <Loading />
          )}
      </div>
    );
  }
}

export default CopyToClipboardModal;
