import React, { PureComponent } from 'react';
import jwt from 'jsonwebtoken';
import { assertTokenAlive, callLambda } from '../helpers';
import { Loading } from '../components';
import queryString from 'query-string';


class ResetPassword extends PureComponent {
  state = {
    newPassword: '',
    confirmedPassword: '',
    emailAddress: '',
    matchError: false,
    response: '',
    isReset: false,
    myToken: '',
  }

  componentDidMount = async () => {
    const { location } = this.props;
    const parsed = queryString.parse(location.search);
    const myToken = parsed.jwt;
    this.setState({ isReset: parsed.reset === 'true' });
    
    try {
      const decoded = jwt.decode(myToken);
      // Since we rely on the server to verify the token we just check the exp here
      assertTokenAlive(decoded);
      console.log(decoded);
      this.setState({ emailAddress: decoded.user.username, myToken });
    } catch (e) {
      this.setState({ response: 'expired' });
      setTimeout(() => {
        window.location.href = '/';
      }, 4000);
    }
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
      matchError: false,
    });
  }

  resetPassword = async (e) => {
    e.preventDefault();
    const { newPassword, confirmedPassword, emailAddress, myToken } = this.state;
    if (newPassword !== confirmedPassword) {
      this.setState({ matchError: true });
      return;
    }

    const data = {
      emailAddress,
      newPassword,
    };

    this.setState({ isFetching: true });

    try {
      await callLambda('post', 'resetPassword', data, myToken);
      this.setState({ isFetching: false, response: 'success' });
    } catch (error) {
      this.setState({ isFetching: false, response: 'serverError' });
    } finally {
      setTimeout(() => {
        window.location.href = '/';
      }, 4000);
    }
  }

  render() {
    const {
      newPassword, confirmedPassword, matchError, response, isFetching, isReset,
    } = this.state;

    const confirmationText = isReset ? 'Your password has been changed.' : 'Your password has been set.';

    let content;
  
    if (response === 'expired') {
      content = (
        <div style={{ textAlign: 'center' }}>
          <h1>This link has expired</h1>
          <p>You will be redirected to login...</p>
        </div>
      );
    } else if (response === 'serverError') {
      content = (
        <div>
          <h1>Server Error</h1>
          <div className="ep-stripe-modal" />
          <span />
          <form className="formContainer">
            <div className="formLine">
              <div className="tpCatDesc">
                <h1>Server Error</h1>
                <p>You will be redirected to login...</p>
              </div>
            </div>
          </form>
        </div>
      );
    } else if (response === 'success') {
      content = (
        <div>
          <h1>Success</h1>
          <div className="ep-stripe-modal" />
          <span />
          <form className="formContainer">
            <div className="formLine">
              <div className="tpCatDesc">
                <p>{confirmationText}</p>
                <p>You will be redirected to login...</p>
              </div>
            </div>
          </form>
        </div>
      );
    } else {
      content = (
        <React.Fragment>
          <h1>{isReset ? 'Reset Password' : 'Set Password'}</h1>
          <form className="formContainer" onSubmit={this.resetPassword}>
            <div className="formLine">
              <div className="tpCatLbl">
                <p>New Password:</p>
              </div>
              <div className="tpCatDesc" style={{ textAlign: 'right' }}>
                <input
                  style={{ marginRight: '15%' }}
                  type="password"
                  name="newPassword"
                  value={newPassword}
                  required
                  minLength={6}
                  onChange={this.handleChange}
                />
              </div>
            </div>
            <div className="formLine">
              <div className="tpCatLbl">
                <p>Confirm Password:</p>
              </div>
              <div className="tpCatDesc" style={{ textAlign: 'right' }}>
                <input
                  style={{ marginRight: '15%' }}
                  type="password"
                  name="confirmedPassword"
                  value={confirmedPassword}
                  required
                  minLength={6}
                  onChange={this.handleChange}
                />
              </div>
            </div>
            <div style={{ textAlign: 'center' }}>
              <span style={{ color: 'red', visibility: matchError ? 'visible' : 'hidden' }}>
                Passwords must match
              </span>
            </div>
            <div className="tpBtnsRow">
              <div className="tpModalBtn">
                <button
                  className="tpBtn"
                  type="submit"
                >
                  {isReset ? 'Reset' : 'Set Password'}
                </button>
              </div>
            </div>
          </form>
        </React.Fragment>
      );
    }

    return (
      <div style={{ margin: '0 400px' }}>
        {!isFetching
          ? (
            <React.Fragment>
              { content }
            </React.Fragment>
          ) : (
            <Loading />
          )}
      </div>
    );
  }
}

export default ResetPassword;
