import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { callLambda } from '../../helpers';
import { Loading } from '..';
import {
  LicenseModal, InfoModal, SuccessModal, ExpiredSessionModal,
} from '.';
import {
  INITIAL, SUCCESS, EXPIRED, SERVER_ERROR,
} from './modalStates';

class GenerateLicenseModal extends PureComponent {
  static propTypes = {
    product: PropTypes.string,
    serialNumber: PropTypes.string,
    userName: PropTypes.string,
    expireDate: PropTypes.string,
    licensedServerId: PropTypes.string,
    closeModal: PropTypes.func.isRequired,
    fetchLicenseData: PropTypes.func.isRequired,
    isFloating: PropTypes.bool.isRequired,
    renewing: PropTypes.bool.isRequired,
  }

  state = {
    hostID: '',
    key: '',
    modalState: INITIAL,
    isFetching: false,
    buttonText: 'Copy',
    error: false,
  }

  handleChange = (event) => {
    if (event.target.name === 'modalHostID') {
      this.setState({ hostID: event.target.value, key: '' });
    }
  }

  handleCopy = () => {
    this.setState({ buttonText: 'Copied' });
  }

  handleClose = () => {
    const { closeModal } = this.props;
    this.setState({
      modalState: INITIAL,
      hostID: '',
      key: '',
      buttonText: 'Copy',
    });
    closeModal();
  };

  generateLicense = async () => {
    const { hostID } = this.state;
    const { licensedServerId, isFloating, fetchLicenseData } = this.props;

    this.setState({ isFetching: true, error: false });

    const data = {
      ...(!isFloating && { hostId: hostID }),
      licensedServer: licensedServerId,
    };

    try {
      const result = await callLambda('post', 'generate', data);
      // display "Invalid Host ID" or "Internal Server Error"
      if (result.error) {
        this.setState({
          key: result.error,
          error: true,
          isFetching: false,
        });
      } else {
        fetchLicenseData();
        this.setState({
          key: result,
          modalState: SUCCESS,
          isFetching: false,
        });
      }
    } catch (e) {
      if (e.message.includes('401')) {
        this.setModalState(EXPIRED);
      } else {
        this.setModalState(SERVER_ERROR);
      };
    };
  };

  setModalState = (modal) => {
    this.setState({ isFetching: false, modalState: modal });
  };

  render() {
    const {
      hostID,
      key,
      modalState,
      isFetching,
      buttonText,
      error,
    } = this.state;
    const {
      product,
      serialNumber,
      userName,
      expireDate,
      isFloating,
      renewing,
    } = this.props;

    let modalContent;

    if (modalState === INITIAL) {
      const action = renewing ? 'Renew' : 'Generate';
      const hostIDText = isFloating ? '' : 'enter the Host ID of the system you wish to assign it to, then ';
      modalContent = (
        <LicenseModal
          header={renewing ? 'Renew License Key' : 'Generate License Key'}
          message={`To ${action.toLowerCase()} your license key for this product, ${hostIDText} click "${action} License Key".`}
          product={product}
          serialNumber={serialNumber}
          userName={userName}
          isFloating={isFloating}
          hostID={hostID}
          expireDate={expireDate}
          error={error}
          licenseKey={key}
          onClose={this.handleClose}
          onChange={this.handleChange}
          onAction={this.generateLicense}
          actionText={`${action} License Key`}
        />
      );
    } else if (modalState === EXPIRED) {
      modalContent = (
        <ExpiredSessionModal />
      );
    } else if (modalState === SERVER_ERROR) {
      modalContent = (
        <InfoModal
          header="Something Went Wrong"
          message="Please retry or contact support."
          onClose={this.handleClose}
          email={process.env.REACT_APP_SUPPORT_LINK}
        />
      );
    } else if (modalState === SUCCESS) {
      modalContent = (
        <SuccessModal
          header={renewing ? 'Key Renewed' : 'Key Generated'}
          licenseKey={key}
          onClose={this.handleClose}
          handleCopy={this.handleCopy}
          buttonText={buttonText}
        />
      );
    }

    return (
      <div className="tpModalContent">
        {!isFetching
          ? (
            <div className="tpModalContent">
              {modalContent}
            </div>
          ) : (
            <Loading />
          )}
      </div>
    );
  }
}

export default GenerateLicenseModal;
