import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { callLambda } from '../../helpers';
import { Loading } from '..';
import { InfoModal, ExpiredSessionModal } from '.';
import { INITIAL, SUCCESS, EXPIRED } from './modalStates';


class ChangePwdModal extends PureComponent {
  static propTypes = {
    closeModal: PropTypes.func.isRequired,
  }

  state = {
    oldPwd: '',
    newPwd: '',
    conPwd: '',
    matchError: false,
    authError: false,
    isFetching: false,
    modalState: INITIAL,
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
      matchError: false,
      authError: false,
    });
  }

  handleClose = () => {
    const { closeModal } = this.props;
    this.setState({
      modalState: INITIAL,
      oldPwd: '',
      newPwd: '',
      conPwd: '',
      matchError: false,
      authError: false,
    });
    closeModal();
  };

  changePassword = async (e) => {
    e.preventDefault();
    const { oldPwd, newPwd, conPwd } = this.state;

    if (newPwd !== conPwd) {
      this.setState({ matchError: true });
      return;
    }

    this.setState({ isFetching: true, authError: false });

    const data = {
      oldPassword: oldPwd,
      newPassword: newPwd,
    };

    try {
      await callLambda('post', 'changePassword', data);

      this.setModalState(SUCCESS);
    } catch (error) {
      if (error.message.includes('401')) {
        this.setModalState(EXPIRED);
      } else {
        this.setState({ isFetching: false, authError: true });
      }
    }
  }

  setModalState = (modal) => {
    this.setState({ isFetching: false, modalState: modal });
  }

  render() {
    const {
      modalState, isFetching, matchError, authError, oldPwd, newPwd, conPwd,
    } = this.state;

    let modalContent;

    if (modalState === INITIAL) {
      modalContent = (
        <div className="tpModalContent">
          <h1>Change Password</h1>
          <div className="ep-stripe-modal" />
          <span />
          <form className="formContainer" onSubmit={this.changePassword}>
            <div className="formLine">
              <div className="tpCatLbl">
                <p>Old Password:</p>
              </div>
              <div className="tpCatDesc" style={{ textAlign: 'right' }}>
                <input
                  style={{ marginRight: '15%' }}
                  type="password"
                  name="oldPwd"
                  value={oldPwd}
                  required
                  onChange={this.handleChange}
                />
              </div>
            </div>
            <div className="formLine">
              <div className="tpCatLbl">
                <p>New Password:</p>
              </div>
              <div className="tpCatDesc" style={{ textAlign: 'right' }}>
                <input
                  style={{ marginRight: '15%' }}
                  type="password"
                  name="newPwd"
                  value={newPwd}
                  required
                  minLength={6}
                  onChange={this.handleChange}
                />
              </div>
            </div>
            <div className="formLine">
              <div className="tpCatLbl">
                <p>Confirm Password:</p>
              </div>
              <div className="tpCatDesc" style={{ textAlign: 'right' }}>
                <input
                  style={{ marginRight: '15%' }}
                  type="password"
                  name="conPwd"
                  value={conPwd}
                  required
                  minLength={6}
                  onChange={this.handleChange}
                />
              </div>
            </div>
            <div style={{ textAlign: 'center' }}>
              <span style={{ color: 'red' }}>
                {matchError && 'Passwords must match'}
                {authError && 'Authorization failed'}
              </span>
            </div>
            <div className="tpBtnsRow">
              <div className="tpModalBtn">
                <button
                  className="tpBtn"
                  type="submit"
                >
                  Accept
                </button>
              </div>
              <div className="tpModalBtn">
                <button
                  className="tpBtn"
                  type="button"
                  onClick={this.handleClose}
                >
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </div>
      );
    } else if (modalState === SUCCESS) {
      modalContent = (
        <InfoModal
          header="Password Changed"
          message="Your password has been successfully changed."
          onClose={this.handleClose}
        />
      );
    } else if (modalState === EXPIRED) {
      modalContent = (
        <ExpiredSessionModal />
      );
    }

    return (
      <div className="tpModalContent">
        {!isFetching
          ? (
            <div className="tpModalContent">
              {modalContent}
            </div>
          ) : (
            <Loading />
          )}
      </div>
    );
  }
}

export default ChangePwdModal;
