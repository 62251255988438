import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import './InputNumber.css';


export default class InputNumber extends PureComponent {
  static propTypes = {
    handleUsers: PropTypes.func.isRequired,
  };

  state = {
    users: 1,
  }

  handleChange = (event) => {
    const eNaN = Number.isNaN(event.target.value);
    if (!eNaN && event.target.value >= 0) {
      this.setState({ users: event.target.value });
    }
  }

  componentDidUpdate = async (prevState) => {
    const { handleUsers } = this.props;
    const { users } = this.state;
    if (prevState.users !== users) {
      handleUsers(users);
    }
  }

  increaseNumber = () => {
    const { users } = this.state;
    this.setState({ users: users + 1 });
  }

  decreaseNumber = () => {
    const { users } = this.state;
    const usersValue = users - 1;
    if (usersValue >= 0) {
      this.setState({ users: usersValue });
    }
  }

  render() {
    const { users } = this.state;

    return (
      <div className="inputNumberContainer">
        <button
          className="tpBtn"
          type="button"
          onClick={this.decreaseNumber}
        >
          -
        </button>
        <input name="users" type="text" placeholder="1" value={users} onChange={this.handleChange} />
        <button
          className="tpBtn"
          type="button"
          onClick={this.increaseNumber}
        >
          +
        </button>
      </div>
    );
  }
}
