import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import logo from '../assets/eggplant.png';
import Menu from './Menu';
import './header.css';
import { readCookie } from '../helpers';

class Header extends PureComponent {
  static propTypes = {
    handleLogout: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool,
    history: PropTypes.object,
  };

  render() {
    const { isAuthenticated, handleLogout, history } = this.props;
    const jwtToken = readCookie('jwt');
    const userName = readCookie('userName');
    let headerContent;

    if (isAuthenticated && jwtToken && jwtToken.length > 0) {
      headerContent = (
        <React.Fragment>
          <div className="ep-welcome">
            Welcome
            <br />
            {userName}
          </div>
          <div className="ep-menu-container">
            <Menu handleLogout={handleLogout} />
          </div>
        </React.Fragment>
      );
    } else {
      headerContent = (
        <React.Fragment>
          <div className="ep-menu-container" />
          <div className="ep-welcome" />
        </React.Fragment>
      );
    }

    return (
      <header className="ep-header">
        <div className="ep-header-grid">
          <div className="ep-logo-container">
            <img src={logo} className="ep-app-logo" alt="eggplant logo" />
          </div>
          <div className="ep-title">
            <button
              onClick={() => (isAuthenticated ? history.push('/casesupport') : null)}
              type="button"
            >
              GREENHOUSE
            </button>
          </div>
          {headerContent}
        </div>
        <div className="ep-stripe" />
      </header>
    );
  }
}

export default withRouter(Header);
