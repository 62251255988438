import React, { PureComponent } from 'react';
import './TempLicense.css';
import { Loading, InputNumber } from '../components';
import { callLambda } from '../helpers';


class TempLicense extends PureComponent {
  state = {
    licenses: [],
    product: '',
    days: '7',
    users: '1',
    isFetching: true,
    errorMessage: '',
    error: false,
  }

  componentDidMount = async () => {
    await this.fetchTempLicenses();
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleUsers = (value) => {
    this.setState({ users: String(value) });
  }

  fetchTempLicenses = async () => {
    try {
      const { match } = this.props;

      const result = await callLambda('post', 'getTemporary', match.params);

      this.setState({ isFetching: false });
      this.setState({ licenses: result });
    } catch (e) {
      this.setState({ isFetching: false });
      this.setState({ licenses: [] });
    }
  }

  callLambdaGenerateTemp = async () => {
    const {
      product,
      days,
      users,
    } = this.state;
    const { match } = this.props;
    const accountID = match.params.accId;

    this.setState({ isFetching: true });

    const data = {
      accountID,
      product,
      days,
      quantity: users,
    };

    try {
      const result = await callLambda('post', 'generateTemporary', data);

      window.location.href = result;
    } catch (e) {
      this.setState({
        errorMessage: e.message,
        error: true,
        isFetching: false,
      });
    }
  }

  render() {
    const {
      licenses,
      errorMessage,
      isFetching,
      error,
    } = this.state;

    const licensesList = licenses.map((lic) => {
      const description = lic.Description ? ` ${lic.Description}` : '';
      return (<li key={lic.Name}><b>{`${lic.Name}`}</b><br></br>{`${description}`}</li>);
    });

    const licensesDropdown = licenses.map(lic => (
      <option key={lic.Name} value={lic.Id}>{lic.Name}</option>));

    const numberOfDays = Array.from(Array(31).keys());
    const daysDropdown = numberOfDays.map(num => (
      <option key={num} value={num + 1}>{num + 1}</option>));

    if (licenses.length === 0) {
      return (
        <div>
          {!isFetching ? (
            <React.Fragment>
              <div className="ep-lic-container">
                <div className="ep-lic-verbiage">
                  <h2 style={{ marginLeft: '0px' }}>Your account is not enabled to create Trial Licenses</h2>
                </div>
              </div>
            </React.Fragment>
            ) : (  <Loading /> )
          }
        </div>
      );
    }

    const content = (
      <React.Fragment>
        <div>
          <div className="ep-lic-container">
            <div className="ep-lic-verbiage">
              <h2 style={{ marginLeft: '0px' }}>Trial License</h2>
              <p>Select a product to create a new trial key</p>
            </div>
          </div>
          <div className="postContainer">
            <form action="POST">
              <div className="formLineTempLicense">
                <p>Product:</p>
                <select name="product" id="selectProduct" defaultValue="select an option" onChange={this.handleChange} required>
                  <option value="select an option">-select an option-</option>
                  {licensesDropdown}
                </select>
              </div>
              <div className="formLineTempLicense">
                <p>Days:</p>
                <select name="days" id="selectDays" defaultValue="7" onChange={this.handleChange}>
                  {daysDropdown}
                </select>
                <p className="usersPar">Users:</p>
                <InputNumber handleUsers={this.handleUsers} />
              </div>
              <div className="formLineTempLicense">
                <button
                  className="tpBtn"
                  type="button"
                  onClick={this.callLambdaGenerateTemp}
                >
                  Create License
                </button>
              </div>
            </form>
          </div>
          <br />
          <div className="licensesContainer">
            <ul>
              {licensesList}
            </ul>
          </div>
          <br />
          <div className="formLine">
            <div className="tpCatLbl">
              <b />
            </div>
            <div className="tpCatDesc">
              <span style={{ color: error ? '#ff0000' : '#000' }}>
                {errorMessage}
              </span>
            </div>
          </div>
          <br />
        </div>
      </React.Fragment>
    );

    return (
      <div>
        {!isFetching
          ? (
            <React.Fragment>
              {content}
            </React.Fragment>
          ) : (
            <Loading />
          )}
      </div>
    );
  }
}

export default TempLicense;
