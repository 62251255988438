import React from 'react';
import './spinner.css';
import spinner from '../assets/spinner.gif';

const Spinner = () => (
  <div className="spinner">
    <img src={spinner} className="spinnerImage" alt="Loading..." />
    <p className="spinnerText">Loading...</p>
  </div>
);

export default Spinner;
