import React, { PureComponent } from 'react';
import { CaseSupportTable } from '../components';

class CaseSupport extends PureComponent {
  render() {
    return (
      <div className="ep-lic-container" style={{ width: '100vw' }}>
        <div className="ep-lic-verbiage">
          <h2 style={{ marginLeft: '0px' }}>Case Support</h2>
          <p>
            The table below displays your case support history.
            You can select a case to view its details,
            or use the button to submit a new case.
          </p>
        </div>
        <CaseSupportTable />
      </div>
    );
  }
}

export default CaseSupport;
