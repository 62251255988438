import React, { Component } from 'react';

class TimeoutModal extends Component {

  handleClose = () => {
    window.location.reload();
  };

  render() {
    return (
      <div className="tpModalContent">
        <h1 className="timeout-title" style={{textAlign: 'center'}}>A Timeout Error Has Occured</h1>
        <div className="ep-stripe-modal" />
        <p style={{textAlign: 'center'}}>Please retry your request </p>
        <div className="tpBtnsRow">
        <button
            className="tpBtn"
            type="button"
            onClick={this.handleClose}>
        OK
        </button>
        </div>
      </div>
    );
  }
}

export default TimeoutModal;