import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { formatDate } from '../../helpers';
import './LicenseModal.css';


class LicenseModal extends PureComponent {
  static propTypes = {
    header: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    product: PropTypes.string.isRequired,
    serialNumber: PropTypes.string.isRequired,
    userName: PropTypes.string.isRequired,
    isFloating: PropTypes.bool.isRequired,
    hostID: PropTypes.string.isRequired,
    expireDate: PropTypes.string,
    error: PropTypes.bool.isRequired,
    licenseKey: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onAction: PropTypes.func.isRequired,
    actionText: PropTypes.string.isRequired,
  };

  state = {
    acceptedEULACheckbox: false,
    disableButton: true,
  };

  updateEULA = () => {
    this.setState({ acceptedEULACheckbox: !this.state.acceptedEULACheckbox }); 
    this.setState({ disableButton: !this.state.disableButton }); 
  }

  onClose = () => {
    this.setState({ acceptedEULACheckbox: false });
    this.setState({ disableButton: true }); 
    this.props.onClose();
  };

  render() {
    const {
      header,
      message,
      product,
      serialNumber,
      userName,
      isFloating,
      hostID,
      expireDate,
      error,
      licenseKey,
      onChange,
      onAction,
      actionText,
    } = this.props;

    const { acceptedEULACheckbox, disableButton } = this.state;

    return (
      <div>
        <h1>{header}</h1>
        <p>{message}</p>
        <div className="ep-stripe-modal" />
        <span />
        <form className="formContainer">
          <div className="formLine">
            <div className="tpCatLbl">
              <p>Product:</p>
            </div>
            <div className="tpCatDesc">
              <p>{product}</p>
            </div>
          </div>
          <div className="formLine">
            <div className="tpCatLbl">
              <p>Serial Number:</p>
            </div>
            <div className="tpCatDesc">
              <p>{serialNumber}</p>
            </div>
          </div>
          <div className="formLine">
            <div className="tpCatLbl">
              <p>User Name:</p>
            </div>
            <div className="tpCatDesc">
              <p>{userName}</p>
            </div>
          </div>
          {!isFloating && (
            <React.Fragment>
              <div
                className="formLineHostID"
                style={{ display: isFloating ? 'none' : 'flex' }}>
                <div className="tpCatLbl">
                  <p className="tpTip">Host ID:</p>
                </div>
                <div className="tpCatDesc">
                  <input
                    style={{ marginLeft: '0px', width: '100%' }}
                    type="text"
                    name="modalHostID"
                    value={hostID}
                    onChange={onChange}
                  />
                </div>
              </div>
              <div className="tpModalBtn">
                <button
                  className="tpBtnTip"
                  type="button"
                  data-tip="A host ID is computer’s unique identifier and is used to lock licenses to that particular computer.<br>You can get the host ID from your application's license panel. Valid Formats:<br>01-23-45-67-89-AB<br>01:23:45:67:89:AB<br>0x0123456789AB">
                  {"What's this?"}
                </button>
                <ReactTooltip multiline />
              </div>
            </React.Fragment>
          )}
          <div className="formLine">
            <div className="tpCatLbl">
              <p>Expire Date:</p>
            </div>
            <div className="tpCatDesc">
              <p>{formatDate(expireDate)}</p>
            </div>
          </div>
            <div className='formLine-eula'>
              <input
                className="eula-input"
                type="checkbox"
                id="eula"
                onChange={this.updateEULA}
                checked={acceptedEULACheckbox}></input>
              <label className="formLine-label" htmlFor="eula">
                I accept the Keysight{' '}
                <a href={process.env.REACT_APP_EULA_LINK}>
                  end user license agreement (EULA)
                </a>
              </label>
            </div>
          <div className="formLine">
            <div className="tpCatLbl">
              <b />
            </div>
            <div className="tpCatDesc">
              <span style={{ color: error ? '#ff0000' : '#000' }}>
                {licenseKey}
              </span>
            </div>
          </div>
          <div className="tpBtnsRow">
            <div className="tpModalBtn">
              {!disableButton && (
                <button
                  className="tpBtn"
                  style={{ width: 200 }}
                  type="button"
                  onClick={onAction}
                >
                  {actionText}
                </button>
              )}
              {disableButton && (
                <button
                  className={acceptedEULACheckbox ? "tpBtn" : "tpBtn-disabled"}
                  style={{ width: 200 }}
                  type="button"
                  onClick={onAction}
                  disabled={!acceptedEULACheckbox}>
                  {actionText}
                </button>
              )}
            </div>
            <div className="tpModalBtn">
              <button className="tpBtn" type="button" onClick={this.onClose}>
                Cancel
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default LicenseModal;
