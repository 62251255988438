/* eslint-disable react/jsx-one-expression-per-line */
import React, { PureComponent } from 'react';
import ReactTable from 'react-table';
import moment from 'moment';
import { insert } from 'ramda';
import ModalComp from './ModalComp';
import KeyButton from './KeyButton';
import { Loading } from '.';
import { formatDate, sortDate, callLambda } from '../helpers';
import './DataTable.css';
import 'react-table/react-table.css';

class DataTable extends PureComponent {
  state = {
    currentTab: 'activeLicenses',
    table: [],
    licenses: {
      activeLicenses: [],
      newLicenses: [],
      renewableLicenses: [],
    },
    showModal: false,
    licensedServerId: '',
    isFetching: true,
    whichModal: '',
    product: '',
    serialNumber: '',
    hostID: '',
    userName: '',
    expireDate: '',
    isFloating: false,
    searchText: '',
    showGroups: false,
    userGroup: '',
    renewing: false,
    licensedServers: [],
  };

  // class field to prevent setting state after component is unmounted
  componentIsMounted = false;

  componentDidMount = async () => {
    this.componentIsMounted = true;
    await this.fetchLicenseData();
    this.setTable('activeLicenses');
  };

  componentWillUnmount() {
    this.componentIsMounted = false;
  }

  setTable = (currentTab) => {
    const { licenses } = this.state;
    this.setState({
      table: [...licenses[currentTab]],
      currentTab,
    });
  };

  activateModal = (
    whichModal, renewing, licensedServerId, product, serialNumber,
    hostID, userName, expireDate, isFloating,
  ) => {
    this.setState({
      renewing,
      showModal: true,
      whichModal,
      licensedServerId,
      product,
      serialNumber,
      hostID,
      userName,
      expireDate,
      isFloating,
    });
  };

  closeModal = () => {
    this.setState({ showModal: false });
  };

  fetchLicenseData = async () => {
    const { currentTab } = this.state;

    try {
      const result = await callLambda('get', 'licenses');
      // filter out licenses with start dates later than today
      const now = moment.utc();
      const filteredLicenses = {
        newLicenses: result.newLicenses
          .filter(ls => !ls.startDate || moment.utc(ls.startDate).isBefore(now)),
        activeLicenses: result.activeLicenses
          .filter(ls => !ls.startDate || moment.utc(ls.startDate).isBefore(now)),
        renewableLicenses: result.renewableLicenses
          .filter(ls => !ls.startDate || moment.utc(ls.startDate).isBefore(now)),
      };
      if (this.componentIsMounted) {
        this.setState({
          licenses: filteredLicenses,
          table: result[currentTab],
          isFetching: false,
          showGroups: result.showGroups,
          userGroup: result.userGroup,
        });
      }
    } catch (e) {
      if (e.message.includes('401')) {
        this.setState({ isFetching: false, showModal: true, whichModal: 'ExpiredSessionModal' });
      } else if (e.message.includes('504')) {
        // in the event of a timeout, bring up TimeoutModal
        this.setState({isFetching: false, showModal: true, whichModal: 'TimeoutModal' });
      } else {
        // in the event of a license fetching error, show empty tables
        this.setState({
          licenses: {
            newLicenses: [],
            activeLicenses: [],
            renewableLicenses: [],
          },
          isFetching: false,
        });
      }
    }
  };

  getTableHeaders = () => {
    const { currentTab, showGroups } = this.state;
    let headers = [
      { Header: 'Product', accessor: 'product', minWidth: 140 },
      {
        Header: 'Username',
        accessor: 'userName',
        maxWidth: 150,
        style: { wordWrap: 'break-word' },
      },
      { Header: 'S/N', accessor: 'serialNumber', maxWidth: 100 },
      { Header: 'Instances', accessor: 'numOfUsers', maxWidth: 110 },
      { Header: 'Host ID', accessor: 'hostId', minWidth: 100 },
      {
        Header: 'Expire Date',
        accessor: 'expireDate',
        maxWidth: 150,
        style: { textAlign: 'center' },
        sortMethod: sortDate,
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        maxWidth: 140,
        sortable: false,
        style: { textAlign: 'center' },
      },
    ];
    if (showGroups) {
      const groupHeader = {
        Header: 'License Group',
        accessor: 'group',
        minWidth: 120,
        style: { textAlign: 'center' },
      };
      headers = insert(0, groupHeader, headers);
    }
    // Insert Key column in different position if Groups column is visible
    if (currentTab === 'activeLicenses') {
      const index = showGroups ? 4 : 3;
      const keyHeader = {
        Header: 'Key',
        accessor: 'key',
        maxWidth: 180,
        minWidth: 120,
        sortable: false,
      };
      headers = insert(index, keyHeader, headers);
    }
    return headers;
  };

  getTableData = () => {
    const { currentTab, table, showGroups } = this.state;

    const tableData = table.map((row) => {
      let buttonName;
      let whichModal;
      if (currentTab === 'newLicenses') {
        buttonName = 'Generate';
        whichModal = 'GenerateLicenseModal';
      } else if (currentTab === 'activeLicenses') {
        buttonName = 'Transfer';
        whichModal = 'TransferLicenseModal';
      } else {
        buttonName = 'Renew';
        whichModal = row.isFloating ? 'GenerateLicenseModal' : 'TransferLicenseModal';
      }
      const renewing = (currentTab === 'renewableLicenses');

      const rowData = {
        isFloating: row.isFloating,
        // hidden poNumber field purely for search
        poNumber: row.poNumber !== null ? row.poNumber : '',
        product: row.product !== null ? row.product : '',
        userName: row.userName !== null ? row.userName : '',
        serialNumber: row.serialNumber !== null ? row.serialNumber : '',
        numOfUsers: row.numberOfUsers !== null ? row.numberOfUsers : '',
        expireDate: row.expireDate !== null ? formatDate(row.expireDate) : '',
        renewDate: row.renewDate !== null ? formatDate(row.renewDate) : '',
        actions: (buttonName === 'Transfer' && (row.isFloating || row.isTemporary)) ? '' : (
          <button
            type="button"
            className="offBtn"
            onClick={() => this.activateModal(whichModal, renewing, row.id, row.product,
              row.serialNumber, row.hostId, row.userName, row.expireDate, row.isFloating)}
          >
            {buttonName}
          </button>),
      };

      if (currentTab === 'activeLicenses') {
        rowData.key = (row.key !== null)
          ? (
            <KeyButton
              myKey={row.key}
              file={row.licenseFile}
              licensedServerId={row.id}
              expireDate={row.expireDate}
              userName={row.userName}
              hostID={row.hostId}
            />
          ) : '';
      }

      if (showGroups) {
        rowData.group = row.group !== null ? row.group : '';
      }

      let hostId;
      if (row.isTemporary) {
        hostId = 'Temporary';
      } else if (row.isFloating) {
        hostId = 'Floating';
      } else {
        hostId = (row.hostId !== null) ? row.hostId : '';
      }

      rowData.hostId = hostId;

      return rowData;
    });

    return tableData;
  };

  openEmailLicensesModal = async () => {
    const { licenses } = this.state;
    const { activeLicenses } = licenses;
    const licensedServers = activeLicenses.map(license => license.id);

    this.setState({
      showModal: true,
      whichModal: 'EmailAllModal',
      licensedServers,
    });
  };

  render() {
    const {
      whichModal, currentTab, isFetching, showModal, licensedServerId, licenses,
      showGroups, product, serialNumber, hostID, userName, expireDate, isFloating,
      searchText, userGroup, renewing, licensedServers,
    } = this.state;

    const columns = this.getTableHeaders();
    let data = this.getTableData();

    if (searchText) {
      const normalizedSearchText = searchText.toUpperCase();
      data = data.filter(row => (
        row.product.toUpperCase().includes(normalizedSearchText)
        || row.userName.toUpperCase().includes(normalizedSearchText)
        || row.serialNumber.includes(normalizedSearchText)
        || row.hostId.toUpperCase().includes(normalizedSearchText)
        || row.expireDate.toUpperCase().includes(normalizedSearchText)
        || row.poNumber.toUpperCase().includes(normalizedSearchText)
        || (showGroups && row.group.toUpperCase().includes(normalizedSearchText))
      ));
    }

    const groupsHeader = userGroup !== '' ? `Showing licenses for group ${userGroup}` : '';

    let content;

    if (isFetching || whichModal === 'ExpiredSessionModal') {
      content = (
        <Loading />
      );
    } else {
      content = (
        <div className="dataTableContainer">
          <div className="emailButtonContainer">
            <button
              type="button"
              className="offBtn"
              style={{ display: currentTab === 'activeLicenses' ? 'inline' : 'none', width: 150 }}
              onClick={this.openEmailLicensesModal}
            >
              Email Licenses
            </button>
          </div>
          <div className="ep-tabs">
            <button
              type="button"
              onClick={() => this.setTable('newLicenses')}
              className={currentTab === 'newLicenses' ? 'on-tab' : 'off-tab'}
            >
              NEW ({licenses.newLicenses.length})
            </button>
            <button
              type="button"
              onClick={() => this.setTable('activeLicenses')}
              className={currentTab === 'activeLicenses' ? 'on-tab' : 'off-tab'}
            >
              ACTIVE ({licenses.activeLicenses.length})
            </button>
            <button
              type="button"
              onClick={() => this.setTable('renewableLicenses')}
              className={currentTab === 'renewableLicenses' ? 'on-tab' : 'off-tab'}
            >
              RENEW ({licenses.renewableLicenses.length})
            </button>
          </div>
          <div className="formLine">
            <input
              className="search-box"
              value={searchText}
              placeholder="Search"
              onChange={e => this.setState({ searchText: e.target.value })}
            />
            <h4 className="groupsHeader">{groupsHeader}</h4>
          </div>
          <ReactTable
            className="-striped"
            data={data}
            columns={columns}
            showPagination={false}
            sortable
            resizable={false}
            minRows={2}
            pageSize={data.length}
          />
        </div>
      );
    }

    return (
      <div className="dataTableContainer">
        <ModalComp
          showModal={showModal}
          licensedServerId={licensedServerId}
          renewing={renewing}
          closeModal={this.closeModal}
          whichModal={whichModal}
          product={product}
          serialNumber={serialNumber}
          hostID={hostID}
          userName={userName}
          expireDate={expireDate}
          fetchLicenseData={this.fetchLicenseData}
          isFloating={isFloating}
          licensedServers={licensedServers}
        >
          <div className="tpBtnsRow">
            <div className="tpModalBtn">
              <button className="tpBtn" onClick={this.closeModal} type="button">
                Cancel
              </button>
            </div>
          </div>
        </ModalComp>
        <div>
          {content}
        </div>
      </div>
    );
  }
}

export default DataTable;
